import { Component, OnInit, ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ToastService } from '../../services/toast.service';
import { ValidateService } from 'src/app/services/validate.service';
import { GoogleAuthService } from "../../services/google-auth.service";
import { Router,NavigationEnd } from '@angular/router';  // Import Router
import { FlashMessageService } from '../../services/flashmessages.service';
import { Location } from '@angular/common'; // Import Location
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent implements OnInit {
  name: string = ""
  username: string = ""
  email: string = ""
  password: string = ""
  loading: boolean = true;
  showpassword: boolean = false;
  isLoading: boolean = false;
  navigationLocation: string = '';
 

  constructor(
    private validateService: ValidateService,
    private authService: AuthService,
    private toastService: ToastService,
    private googleAuthService: GoogleAuthService,
    private flashMessage: FlashMessageService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    private router: Router,  // Inject Router in the component
    private route: ActivatedRoute
    
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.navigationLocation = params['type'];
    });

    this.googleAuthService.loadGoogleSDK()
      .then(() => {
        this.googleAuthService.initializeGoogleSignIn(this.handleCredentialResponse.bind(this));
      })
      .catch(error => {
        console.error("Google SDK load error:", error);
    });

    

    

  }

 
  handleCredentialResponse(response: any): void {
    if (response && response.credential) {
      fetch("/api2/googleRegister", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          credential: response.credential
        })
      })
      .then(response => {
        if (!response.ok) {
          this.flashMessage.display('Failed to register with Google', 'danger', 2000);
        }
        return response.json();
      })
      .then(data => {
        if (data.message === 'Registration successful') {
          // Log the user information (for example, from the response)
          console.log("User information:", data.userInfo);  // Assuming the backend sends user data

          this.registerGoogleSubmit(data.userInfo)
  
          // Navigate to the register-success page on success
          // this.router.navigate(['/register-success']);
        } else {
          console.error("Unexpected response:", data);
        }
      })
      .catch(error => {
        this.flashMessage.display(error, 'danger', 2000);
     
      });
    } else {
      this.flashMessage.display('Invalid response or missing credential', 'danger', 2000);
    }
  }

  registerGoogleSubmit(data): void {
    const user = {
      name: data.name,
      email: data.email,
      username: data.given_name,
      password: data.sub
    };

  

    this.authService.registerUser(user).subscribe((data) => {
      if (data.err) {
        this.flashMessage.display(data.msg, 'danger', 2000);
        this.changeDetectorRef.markForCheck();
      } else if (data.success) {
        this.flashMessage.display('sucess', 'success', 2000);
        this.onLoginGoogleSubmit(user)
        this.changeDetectorRef.markForCheck();
        // this.toastService.showToast( 'success')
      } else {
        this.flashMessage.display(data.msg, 'danger', 2000);
        // this.changeDetectorRef.markForCheck();
      }
    });
  }
  

  register(): void {
    if (this.checkValidForm()) {
      this.flashMessage.display('error', 'danger', 2000);
      return;
    }

    if (!this.validateService.validateEmail(this.email)) {
      this.flashMessage.display('invalid email', 'danger', 2000);
      return;
    }
    this.isLoading = true
    const user = {
      name: this.name,
      email: this.email,
      username: this.username,
      password: this.password
    };

    this.authService.registerUser(user).subscribe((data) => {
      if (data.err) {
        this.isLoading = false
        this.flashMessage.display(data.msg, 'danger', 2000);
      } else if (data.success) {
        this.onLoginSubmit()
        this.changeDetectorRef.markForCheck();
       
      } else {
        this.isLoading = false
        this.changeDetectorRef.markForCheck();
        this.flashMessage.display('Email taken', 'danger', 2000);
      }
    });
  }

  onLoginSubmit() {
    const user = {
      email: this.email,
      password: this.password
    };

    this.authService.authenticateUser(user).subscribe((data) => {
      if (data.success) {
        this.isLoading = false
        this.changeDetectorRef.markForCheck();
        this.authService.storeUserData(data.token, data.user);
        this.checkRoute()
        // this.router.navigate(['/']);
        this.flashMessage.display('You are now logged in', 'success', 2000);
      } else {
        this.isLoading = false
        this.changeDetectorRef.markForCheck();
        this.toastService.showToast(data.msg, 'error');
        // this.flashMessage.display(data.msg, 'danger', 2000);
      }
    });
  }
  
  onLoginGoogleSubmit(user) {
    const userInfo = {
      email: user.email,
      password: user.password
    };

    this.authService.authenticateUser(userInfo).subscribe((data) => {
      if (data.success) {
        this.changeDetectorRef.markForCheck();
        this.authService.storeUserData(data.token, data.user);
        this.checkRoute()
        // this.router.navigate(['/']);
        this.flashMessage.display('You are now logged in', 'success', 2000);
      } else {
        this.changeDetectorRef.markForCheck();
        this.flashMessage.display(data.msg, 'danger', 2000);
      }
    });
  }


  checkRoute(): void {
    if (this.navigationLocation === 'login') {
      // If the previous route was register or login, go to home
     
        window.history.go(-2); // Go back two steps
     
    } else {
      // Otherwise, go back to the last page
      this.location.back();
    }
  }

  onImageLoad() {
    this.loading = false;
  }

  toggleShowPassword() {
    this.showpassword = !this.showpassword
  }

  checkValidForm() {
    return  this.name.trim().length! <= 0 || this.username.trim().length! <= 0 || this.email.trim().length <= 0 || this.password.trim().length <= 0
  }

  login() {
    
      // this._router.navigate(["register"])
      this.router.navigate(["login"], { queryParams: { type: "register" } });
  
    // this.router.navigate(["login"])
  }
}
