import { ChangeDetectionStrategy, Component,OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { UiService } from './services/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  isHide: boolean = false;
  isLoading = false;
  title = 'vlogmo';
  constructor(
    private router: Router,
    private uiService: UiService
  ) {
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     this.uiService.showLoading();
    //   } else if (event instanceof NavigationEnd) {
    //     this.uiService.hideLoading();
    //   }
    // });
  }
  ngOnInit() {
    this.router.events.subscribe(() => {
      // this.checkIfVideoPage();
      if (event instanceof NavigationStart) {
            this.uiService.showLoading();
          } else if (event instanceof NavigationEnd) {
            this.uiService.hideLoading();
          }
    });
  }

  // checkIfVideoPage() {
  //   const currentRoute = this.router.url;
  //   this.isHide = currentRoute.startsWith('/video/') || currentRoute.includes('register');
  // }
}