<div [class.active-overlay]="showoverlay" class="overlay">
    <div class="overlay-content">
        <form (submit)="search()">
            <span class="closebtn" (click)="toggleShowOverlay()" title="Close Overlay"><i
                    class="material-icons">arrow_back</i></span>
            <input type="text" placeholder="Search.." [(ngModel)]="searchContent" name="search" autocomplete="off" />
            <button type="submit"><i class="material-icons">search</i></button>
        </form>
    </div>
</div>

<nav *ngIf="(mobile | async)!.matches" class="nav d-flex w-100 justify-content-around"
    [style.marginBottom.px]="isNavHidden ? -55 : 0">
    <div class="item__nav" (click)="navItem.action()" *ngFor="let navItem of menuBottomNav; let i = index"
        [ngClass]="currentNavBottom == navItem.id ? 'active' : ''">
        <div class="setSize"  [class.create]="i === 2"  [class.message]="i === 3">
            <img class="w-75 h-75" [src]="currentNavBottom == navItem.id ? navItem.iconActive : navItem.icon">
            <span *ngIf="Usermessage === 'true' && i === 3" class="icon__badge"></span>
        </div>
        <!-- <span *ngIf="currentNavBottom == navItem.id">{{navItem.name}}</span> -->
        <div *ngIf="currentNavBottom == navItem.id" class="activeLine"></div>
    </div>
</nav>