import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, Injector, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
// import { LoginComponent } from 'src/app/loginold/login.component';
import { AuthService } from 'src/app/services/auth.service';
import { EEvent, EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-nav-bottom',
  templateUrl: './nav-bottom.component.html',
  styleUrls: ['./nav-bottom.component.css']
})
export class NavBottomComponent implements OnInit {

  mobile: Observable<BreakpointState> =
    this.breakpointObserver.observe('(max-width: 576px)');
  isNavHidden = false;
  itemSize = 50; // Default item size
  showoverlay: boolean = false;
  // overlayRefs: OverlayRef;
  userId: string;
  usermessage: any;
  pageName: string;
  searchContent: string = ""

  currentNavBottom: number = 0;
  connectedAccountId:string;
  Usermessage: string;

  menuBottomNav = [
    {
      id: 0,
      name: "Home",
      icon: "https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/home2.svg",
      iconActive: "https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/home-active.svg",
      action: () => {
        this.currentNavBottom = 0;
        this._router.navigate(["/"])
      }
    },
    {
      id: 1,
      name: "Search",
      icon: "https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/search2.svg",
      iconActive: "https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/search-input.svg",
      action: () => {
        this.currentNavBottom = 1;
        this.toggleShowOverlay()
      }
    },
    {
      id: 2,
      name: "Create",
      icon: "https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/creat.svg",
      iconActive: "https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/create.png",
      action: () => {
        this.currentNavBottom = 2;
        this.onBottomNav('studio/user')
      }
    },
    {
      id: 3,
      name: "Inbox",
      icon: "https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/messages.svg",
      iconActive: "https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/message-active.svg",
      action: () => {
        this.currentNavBottom = 3;
        this.onBottomNav2('messages') 
      }
    },
    {
      id: 4,
      name: "Profile",
      icon: "https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/creators.svg",
      iconActive: "https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/creator-active.svg",
      action: () => {
        this.currentNavBottom = 4;
        this.onSelectpro2()
      }
    }
  ]

  constructor(
    private breakpointObserver: BreakpointObserver,
    public overlays: Overlay,
    private _router: Router,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    public viewContainerRefs: ViewContainerRef,
    private injectors: Injector,
    private _eventService: EventService
  ) {
    this._eventService.receive().pipe(
      tap(res => {
        if (res.action === EEvent.UPDATE_NAV_HIDDEN) {
          this.isNavHidden = res.payload
        }
      })
    ).subscribe();
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 576px)']) // Add more breakpoints if needed
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.itemSize = 330; // Smaller size for small screens
        } else {
          this.itemSize = 50; // Default size for larger screens
        }
        console.log(`Item Size Set To: ${this.itemSize}`); // Optional debugging
      });

    this.authService.getProfile().subscribe(
      (profile) => {
        this.userId = profile.user.id;
        this.pageName = profile.user.searchPageName
        this.connectedAccountId = profile.connectedAccountId;
        this.Usermessage = profile.user.message;

        try {
          this.usermessage = profile.user.message;
        } catch (error) {
          console.log('null');
        } finally {
          this.changeDetectorRef.markForCheck();
        }
      }
    );

  }


  onBottomNav(a) {
    // if (a === 'messages') {
    //   this.usermessage = 'false';
    //   const user = {
    //     recieverid: this.userId,
    //     message: 'false'
    //   };

    //   this.changeDetectorRef.markForCheck();

    //   this.authService.updateUserProfile(user).subscribe((profile) => {
      
    //   });
    // }

    if (this.authService.loggedIn()) {

      if (this.connectedAccountId) {
        this._router.navigate(['/' + a]);
      } else {
        this._router.navigate(['/studio/creator']);
      }
    } else {
      this.showGlobalOverlay();
    }
    
    
  }

  onBottomNav2(a) {
    if (a === 'messages') {
      this.usermessage = 'false';
      const user = {
        recieverid: this.userId,
        message: 'false'
      };

      this.changeDetectorRef.markForCheck();

      this.authService.updateUserProfile(user).subscribe((profile) => {
        // console.log(profile);
      });
    }

    if (this.authService.loggedIn()) {

      this._router.navigate(['/' + a]);
    } else {
      this.showGlobalOverlay();
    }
  }
 
  

  onSelectpro2() {
    var pageName = this.pageName;
    if (this.authService.loggedIn()) {

      if (this.connectedAccountId) {
        this._router.navigate([`p/${pageName}`]);
      } else {
        this._router.navigate(['/studio/creator']);
      }
    } else {
      this.showGlobalOverlay();
    }
  }

  

  showGlobalOverlay() {
    this._router.navigate(["login"])
  }

  toggleShowOverlay() {
    this.showoverlay = !this.showoverlay;
    this.changeDetectorRef.markForCheck();
  }

  createInjectors(overlayRefs: OverlayRef): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(OverlayRef, overlayRefs);
    return new PortalInjector(this.injectors, injectorTokens);
  }

  search() {
    this.showoverlay = false;
    this.changeDetectorRef.markForCheck();

    this._router.navigate(['/search'], {
      queryParams: { search: this.searchContent, page: '1' }
    });

    this.searchContent = null;
    this.changeDetectorRef.markForCheck();
  }
}
