<!-- <div id="google-signin-btn"></div> -->
<!-- Custom Google Sign-In Button -->
<!-- <div (click)="handleGoogleSignIn()" class="login__social">
    <img src="../assets/img/logo-google.svg">
    <span>Continue with Google</span>
</div> -->


<section class="signup__container">
    <div class="signup__content">
        <div class="signup">
            <div class="signup__logo">
                <img [routerLink]="['/']" class="header__logo pointer"
                    src="https://s3bucket190.s3.us-east-1.amazonaws.com/download__4___2_-removebg-preview%2B(1).png" />
                <span class="header__Vlogmo">Vlogmo</span>
            </div>
            <div class="signup__title">
                Sign up
            </div>
            <div class="signup__form">
                <div class="signup__form__field">
                    <span class="signup__form__label">Full name</span>
                    <input class="signup__form__input" [(ngModel)]="name" placeholder="Enter your full name">
                </div>
                <div class="signup__form__field">
                    <span class="signup__form__label">User name</span>
                    <input class="signup__form__input" [(ngModel)]="username" placeholder="Enter your user name">
                </div>
                <div class="signup__form__field">
                    <span class="signup__form__label">Email</span>
                    <input type="email" class="signup__form__input" [(ngModel)]="email" placeholder="Enter your email">
                </div>
                <div class="signup__form__field">
                    <span class="signup__form__label">Password</span>
                    <input [type]="showpassword ? 'text' : 'password'" class="signup__form__input"
                        [(ngModel)]="password" placeholder="Enter your password">
                    <i class="material-icons toggle__password" (click)="toggleShowPassword()">
                        {{showpassword ? 'visibility' : 'visibility_off'}}
                    </i>
                </div>
                <div class="btn__signup" [class.opacity]="checkValidForm()" (click)="register()">
                    Sign up <span class="ml-2 text-white"><mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner> </span>
                </div>
            </div>
            <div class="signup__notice">
                Already have an account? <span (click)="login()">Sign In</span>
            </div>
            <div class="or__option">
                <div class="line"></div>
                <span>OR</span>
                <div class="line"></div>
            </div>

            <!-- <div  id="google-signin-btn"></div> 

            <div class="login__social mt-2">
                <img src="../assets/img/logo-apple.svg">
                <span>Continue with Apple</span>
            </div> -->
        </div>
        <div  id="google-signin-btn"></div> 

            <!-- <div class="login__social mt-2">
                <img src="../assets/img/logo-apple.svg">
                <span>Continue with Apple</span>
            </div> -->
    </div>
</section>