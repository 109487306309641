<div class="creators__list">
    <div class="creator__header">
        <span>Top Creators</span>
        <a [routerLink]="['/creators']">View all</a>
    </div>
    <div class="creators__data" *ngIf="!!popularInstructors">
        <div class="creator" *ngFor="let creator of popularInstructors.slice(0, displayLimit)">
            <img [src]="creator.User.profile">
            <div class="creator__info">
                <span>{{creator.User.pageName}}</span>
                <div class="d-flex align-items-center">
                    <span class="creator__videos">
                        {{ creator.totalVideos }} videos |
                    </span>&nbsp;
                    <span (click)="onSelectpro(creator.User.searchPageName)" class="creator__status">Page</span>
                </div>
            </div>
        </div>
    </div>
</div>