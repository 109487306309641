<section [ngStyle]="{'display': 'flex', 'flex-direction': isVerticalScroll ? 'column' : 'row'}">
    <div class="list-item" [ngStyle]="{'flex-direction': isVerticalScroll ? 'column' : 'row'}"
        [ngClass]="{'reverse-order': isScrollToTop}">
        <ng-content></ng-content>
    </div>
    <div class="loading-item" #loading
        [ngStyle]="{'width': isVerticalScroll ? '100%' : 'auto', 'display' : isDone ? 'none' : 'block'}"
        [ngClass]="{'reverse-order': isScrollToTop}">
        <ng-container [ngTemplateOutlet]="templateLoading"></ng-container>
    </div>
</section>