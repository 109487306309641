import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllVideosComponent } from '../components/all-videos/all-videos.component'; // Import the component
import { MatCardModule } from '@angular/material/card'; // Import MatCardModule
import { TimeagoModule } from 'ngx-timeago';

@NgModule({
  declarations: [AllVideosComponent],  // Declare the component here
  imports: [CommonModule,MatCardModule,TimeagoModule],  // Import necessary modules
  exports: [AllVideosComponent]  // Export it to make it available in other modules
})
export class sharedAllVideosModule { }
