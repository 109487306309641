import { Injectable } from '@angular/core';

@Injectable()
export class ValidateService {
  constructor() {}

  validateRegister(user) {
    if (
      user.name == undefined ||
      user.email == undefined ||
      user.username == undefined ||
      user.password == undefined
    ) {
      return false;
    } else {
      return true;
    }
  }

  validateContact(user) {
    if (
      user.name == undefined ||
      user.email == undefined ||
      user.lastname == undefined ||
      user.message == undefined
    ) {
      return false;
    } else {
      return true;
    }
  }

  validateResolution(uploads) {
    if (
      uploads.newid == undefined ||
      uploads.id == undefined ||
      uploads.uploads == undefined ||
      uploads.quality == undefined
    ) {
      return false;
    } else {
      return true;
    }
  }

  validateCourse2(uploads) {
    if (
      uploads.uploads == undefined ||
      uploads.title == undefined ||
      uploads.objective == undefined
    ) {
      return false;
    } else {
      return true;
    }
  }

  validateCourse(uploads) {
    if (
      uploads.course == undefined ||
      uploads.price == undefined ||
      uploads.dollar == undefined ||
      uploads.checks != true ||
      uploads.check != true ||
      uploads.title == undefined ||
      uploads.about == undefined ||
      uploads.goals[0].goals === '' ||
      uploads.objective == undefined ||
      uploads.uploads == undefined
    ) {
      return false;
    } else {
      return true;
    }
  }
  validateResume(resume) {
    if (
      resume.name == undefined ||
      resume.email == undefined ||
      resume.phone == undefined
    ) {
      return false;
    } else {
      return true;
    }
  }

  validateReview(uploads) {
    if (
      uploads.course == undefined ||
      uploads.length == undefined ||
      uploads.lectures == undefined ||
      uploads.courselevel == undefined ||
      uploads.checks != true ||
      uploads.check != true
    ) {
      return false;
    } else {
      return true;
    }
  }
  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}
