import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoSkeletonComponent } from '../components/video-skeleton/video-skeleton.component'; // Import the component

@NgModule({
  declarations: [VideoSkeletonComponent],  // Declare the component here
  imports: [CommonModule],  // Import necessary modules
  exports: [VideoSkeletonComponent]  // Export it to make it available in other modules
})
export class videoSkeletonModule { }
