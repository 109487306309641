<!-- <h3>stats{{avgRating}}<h3> -->
<div class="Ratings">
  <img
    src="https://placeatthetable.net/wp-content/uploads/2016/02/goldstarratings1.png?w=869"
    onerror="this.src='https://www.startupschool.org/avatar.svg'"
  />
  <div class="Ratings__info">
    <h5>Average Rating</h5>
    <span class="text-muted"
      >average rating for all videos is {{ avgRating | number: '1.2-2' }}</span
    >
  </div>
</div>
