import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-followers',
  templateUrl: './followers.component.html',
  styleUrls: ['./followers.component.css']
})
export class FollowersComponent implements OnInit {
  fetch: string;
  followers: any;
  following: any;
  all: any;

  constructor(
    public dialogRef: MatDialogRef<FollowersComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    this.fetch = this.data.fetch;
    this.followers = this.data.followers;
    this.following = this.data.following;
  }

  ngOnInit() {
    // this.authService.getProfile().subscribe(profile => {
    //
    //   this.myid = profile.user._id
    //
    //
    // });

    if (this.fetch == 'followers') {
      this.all = this.followers;
    } else {
      this.all = this.following;
    }

    // this.dialogRef.close();
  }
  
  lowercase(a) {
    if (typeof a !== 'string') {
      throw new Error('Invalid input: email must be a string');
    }
    return a.toLowerCase().replace(/\s+/g, '');
  }

  subscribes(a) {

    // setTimeout(()=>{
    //   window.location.reload();
    // }, 100);
    const pageName = this.lowercase(a);
    
    this.dialogRef.close();
    this.router.navigate([`p/${pageName}`]);
  }
}
