import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface FlashMessage {
  message: string;
  type: 'success' | 'danger';
}

@Injectable({ providedIn: 'root' })
export class FlashMessageService {
  private messageSource = new BehaviorSubject<FlashMessage | null>(null);
  public message$ = this.messageSource.asObservable();

  display(
    message: string,
    type: 'success' | 'danger',
    duration: number = 3000
  ) {
    this.messageSource.next({ message, type });
    setTimeout(() => this.messageSource.next(null), duration);
  }
}
