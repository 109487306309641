import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subject, Subscription, debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'app-infinity-scroll',
  templateUrl: './infinity-scroll.component.html',
  styleUrls: ['./infinity-scroll.component.css']
})
export class InfinityScrollComponent implements OnInit {

  private readonly TIME_DELAY = 100;
  private readonly EVENT_NAME = 'scroll';
  private readonly PERCENT_LOADING_ELEMENT = 0.33;

  @Input() elementScroll!: HTMLElement;
  @Input() isLoading: boolean = false;
  @Input() isDone: boolean = false;
  @Input() isScrollToTop: boolean = false;
  @Input() isVerticalScroll: boolean = true;
  @Input() templateLoading!: TemplateRef<HTMLElement>;

  private _isUnsubscribe: boolean = false;
  @Input() set isUnsubscribe(value: boolean) {
    this._isUnsubscribe = value;
    if (value) {
      this._sub.unsubscribe();
    }
  }
  get isUnsubscribe() {
    return this._isUnsubscribe;
  }

  @Input() handlingAfterGetData: Subject<any> = new Subject();

  @Output() scrollEnd = new EventEmitter<HTMLElement>();

  @ViewChild('loading') elementLoading!: ElementRef<HTMLDivElement>;
  

  private current = 0;
  private _sub: Subscription = new Subscription();

  constructor() { }

  ngOnInit(): void {
    this.handlingAfterGetData.subscribe(() => {
      if (this.isScrollToTop)
        setTimeout(() => {
          this.elementScroll.scrollTop = this.elementScroll.scrollTop + this.elementScroll.scrollHeight - this.current;
        });
    })
  }

  ngAfterViewInit(): void {
    if (this.elementScroll) this._sub.add(this.addEventScroll());
    if (this.isScrollToTop) {
      this.elementScroll.style.scrollBehavior = 'auto';
      setTimeout(() => {
        this.elementScroll.scrollTop = this.elementScroll.scrollHeight;
      }, 100);
    }
  }

  private addEventScroll(): Subscription {
    return fromEvent(this.elementScroll, this.EVENT_NAME).pipe(debounceTime(this.TIME_DELAY))
      .subscribe(() => {
        if (!this.isLoading) {
          if (this.checkScrollEndHorizontal()) {
            this.current = this.elementScroll.scrollHeight;
            this.scrollEnd.emit(this.elementScroll);
          }
        }
      })
  }

  private checkScrollEndHorizontal(): boolean {
    const elementLoading: HTMLDivElement = this.elementLoading.nativeElement;
    return this.isScrollToTop ? this.elementScroll.scrollTop === 0 : this.elementScroll.scrollTop + this.elementScroll.getBoundingClientRect().height >= this.elementScroll.scrollHeight - elementLoading.getBoundingClientRect().height * this.PERCENT_LOADING_ELEMENT;
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }


}
