import { Component, OnInit, Input, ViewContainerRef, Injector, Output, EventEmitter,OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-top-creator',
  templateUrl: './top-creator.component.html',
  styleUrls: ['./top-creator.component.css']
})
export class TopCreatorComponent implements OnInit {
  displayLimit: number = 8; // Default for large screens
  
  @Input() popularInstructors: any;  // Receives all videos from HomeComponent


  @Output() loginEvent = new EventEmitter();
  constructor(
    private authService: AuthService,
    private router: Router,
    private injectors: Injector,
    private breakpointObserver: BreakpointObserver,
  ) { }

  onSelectpro(user) {
    if (this.authService.loggedIn()) {
      this.router.navigate([`p/${user}`]);
    } else {
      this.login();
    }
  }

  login() {
    this.router.navigate(["login"])
  }

  createInjectors(overlayRefs: OverlayRef): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(OverlayRef, overlayRefs);
    return new PortalInjector(this.injectors, injectorTokens);
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 822px)']) // Add more breakpoints if needed
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.displayLimit = 4; // Smaller size for small screens
        } else {
          this.displayLimit = 8; // Default size for larger screens
        }
       
      });
   
  }

}
