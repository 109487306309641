<ng-container *ngFor="let data of all; let i = index; trackBy: trackById">
  <div *ngIf="data.videoType === 'course' || data.videoType === 'playlist'" class="card2"
    [style.--card-background-color]="data.dominantColor">
    <div (click)="onSelect($event,data)" class="card-inner figure">
      <img [src]="data.pic" class="img" />

      <div *ngIf="
        data.radioButtonValue === 'subscription' ||
        data.radioButtonValue === 'sub&&purch' || data.radioButtonValue === 'purchase' " class="card__time-wrapper">
        <div class="card__backdrop">
          <div class="lock__wrapper">
            <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/lock.png">
          </div>
        </div>
      </div>

      <div class="card__views-wrapper">
        <div class="card__text">{{ data.episodes }} episodes</div>
      </div>
    </div>

    <div class="card__info">
      <div class="card__infoImage">
        <mat-card class="mat-card mat-focus-indicator" style="display: none"></mat-card>
        <img mat-card-avatar [src]="data.profile" alt="User Avatar" class="mat-card-avatar" />
      </div>

      <div (click)="onSelect($event,data)" class="card__infoText">
        <div class="card__infoTextMain">
          <h3 class="mb-0">{{ fromSearchPage ? data.title : data.courseTitle }}</h3>
          <div class="flex">
            <p class="mb-0">{{ data.name }} <span *ngIf="page">|</span>
              &nbsp;&nbsp;
            </p>
            <span *ngIf="page" class="creator__status">Page</span>
          </div>
          <p class="mb-0">
            {{ data.newview }} views |
            {{ data.date | timeago }}
          </p>
        </div>
        <div class="card__infoTextTags">
          <div class="tag">
            <button *ngIf="
                  data.radioButtonValue === 'subscription' ||
                  data.radioButtonValue === 'sub&&purch'
                " class="tagButton">
              <span>Membership</span>
            </button>
            <button *ngIf="
                  data.radioButtonValue === 'purchase' ||
                  data.radioButtonValue === 'sub&&purch'
                " class="tagButton">
              <span>Buy</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="data.videoType === 'singleVideo'" class="video-card pointer">
    <div (click)="onSelect($event,data)" class="card__mainImageCover figure">
      <img [src]="data.pic" />

      <div *ngIf="
        data.radioButtonValue === 'subscription' ||
        data.radioButtonValue === 'sub&&purch' || data.radioButtonValue === 'purchase' " class="card__time-wrapper">
        <div class="card__backdrop">
          <div class="lock__wrapper">
            <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/lock.png">
          </div>
        </div>
      </div>

      <div class="card__date-wrapper">
        <div class="card__text">{{ data.timeStamp }}</div>
      </div>
    </div>

    <div class="card__info">
      <div class="card__infoImage">
        <mat-card style="display: none"></mat-card>
        <img mat-card-avatar [src]="data.profile" alt="User Avatar" class="mat-card-avatar" />
      </div>

      <div (click)="onSelect($event,data)" class="card__infoText">
        <div class="card__infoTextMain">
          <h3 class="mb-0">{{ fromSearchPage ? data.title : data.courseTitle }}</h3>
          <div class="flex">
            <p class="mb-0">{{ data.name }} <span *ngIf="page">|</span>
              &nbsp;&nbsp;
            </p>
            <span *ngIf="page" class="creator__status">Page</span>
          </div>
          <p class="mb-0">
            {{ data.newview }} views | {{ data.date | timeago }}
          </p>
        </div>
        <div class="card__infoTextTags">
          <div class="tag">
            <button *ngIf="
                  data.radioButtonValue === 'subscription' ||
                  data.radioButtonValue === 'sub&&purch'
                " class="tagButton">
              <span>Membership</span>
            </button>
            <button *ngIf="
                  data.radioButtonValue === 'purchase' ||
                  data.radioButtonValue === 'sub&&purch'
                " class="tagButton">
              <span>Buy</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="data.videoType === 'live'" class="video-card pointer">
    <div (click)="onSelect($event,data)" class="card__mainImageCover figure">
      <img [src]="data.pic" />
      <div class="card__views-wrapper"></div>
      <div class="card__date-wrapper">
        <div class="card__text live">
          Live
        </div>
      </div>
    </div>

    <div class="card__info">
      <div class="card__infoImage">
        <mat-card style="display: none"></mat-card>
        <img mat-card-avatar [src]="data.profile" alt="User Avatar" class="mat-card-avatar" />
      </div>

      <div (click)="onSelect($event,data)" class="card__infoText">
        <div class="card__infoTextMain">
          <h3 class="mb-0">{{ fromSearchPage ? data.title : data.courseTitle }}</h3>
          <div class="flex">
            <p class="mb-0">{{ data.name }} <span *ngIf="page">|</span>
              &nbsp;&nbsp;
            </p>
            <span *ngIf="page" class="creator__status">Page</span>
          </div>

          <p class="mb-0">
            {{ data.newview }} Watching | Live
          </p>
        </div>
        <div class="card__infoTextTags">
          <div class="tag">
            <button *ngIf="
                data.radioButtonValue === 'subscription' ||
                data.radioButtonValue === 'sub&&purch'
              " class="tagButton">
              <span>Membership</span>
            </button>
            <button *ngIf="
                data.radioButtonValue === 'purchase' ||
                data.radioButtonValue === 'sub&&purch'
              " class="tagButton">
              <span>Buy</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>