<div
  *ngIf="flashMessage"
  [ngClass]="{
    'flash-message': true,
    success: flashMessage.type === 'success',
    danger: flashMessage.type === 'danger'
  }"
>
  {{ flashMessage.message }}
</div>
