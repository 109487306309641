import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
// import { LoginComponent } from '../../loginold/login.component';
import { RegisterComponent } from '../register/register.component';
import { AuthService } from '../../services/auth.service';
import { FilterService } from '../../services/filter.service';
// import { OwlOptions } from 'ngx-owl-carousel-o';
import { ElementQueries } from 'css-element-queries';
import { Subscription } from 'rxjs';
import { EEvent, EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', './home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  public virtualScrollViewport?: CdkVirtualScrollViewport;

  itemSize = 50; // Default item size
  CourseSubscription: Subscription;
  overlayRef: OverlayRef;
  overlayRefs: OverlayRef;
  show: boolean = false;
  shows: boolean = false;
  showy: boolean = false;
  currentPage: string = "all"
  showFilter: boolean = false;
  newCourses: Object;
  newCourses2: Object;
  topCourses: Object;
  topCourses2: Object;
  freeCourses: Object;
  freeCourses2: Object;
  popularInstructors: Object;
  mobile: Observable<BreakpointState> =
    this.breakpointObserver.observe('(max-width: 576px)');
  isHandset: Observable<BreakpointState> =
    this.breakpointObserver.observe('(max-width: 900px)');
  loading = false;
  page = 1;
  filtertest = 'Highest Rated';
  videoselect: string;
  first: boolean = true;
  rating = 0;
  lengthfilter: any;
  durations: any;
  Usermessage: any;
  //length
  lengthOptions: Array<{ gte: number; lte?: number }> = [
    { gte: 0, lte: 1 },   // 0 - 1 hour
    { gte: 1, lte: 2 },   // 1 - 2 hours
    { gte: 2, lte: 4 },   // 2 - 4 hours
    { gte: 4 }            // 4+ hours
  ];
  
  // selectedLengths: Array<{ gte: number; lte?: number }> = [];
  selectedReview: number | null = null; // Stores selected rating
  selectedLengths: Array<{ gte: number; lte?: number }> = []; // Stores selected lengths
//  length
ratingOptions = [
  { value: 4.5, label: '4.5 & up' },
  { value: 4.0, label: '4.0 & up' },
  { value: 3.5, label: '3.5 & up' },
  { value: 3.0, label: '3.0 & up' }
];
  totalRecords: string;
  currentFilterType: string = 'all'
  clearFilter:boolean = false;
  // all: Array<any>;
  all: any[] = [];           // Array to display videos
  originalAll: any[] = [];   // Array to keep the original video list
  isFetchDone: boolean = false;

  nextCursor: any;
  showoverlay: boolean = false;
  search: string;
  isLoading: boolean = false;
  isNavHidden = false;
  private lastScrollOffset = 0;

  currentFilter: string = 'all';
  openFilter:boolean = false;

  constructor(
    public overlay: Overlay,
    public overlays: Overlay,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private router: Router,
    private injector: Injector,
    private injectors: Injector,
    public viewContainerRefs: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
    private _eventService: EventService,
    private renderer: Renderer2,
    private filterService: FilterService
  ) {
    if (typeof window === 'undefined') {
      this.loading = true;
    }
    if (typeof window !== 'undefined') {
      this.loading = false;
    }


  }

  filterVideos(type: string) {
    // Filter the videos based on videoType
    this.currentPage = type
    this.all = this.originalAll.filter(video => video.videoType === type);
    this.changeDetectorRef.markForCheck(); // Notify Angular about the changes
  }

  resetVideos() {
    // Reset to the original video list
    this.currentPage = "all"
    this.all = this.originalAll;
    this.changeDetectorRef.markForCheck(); // Notify Angular about the changes
  }


  //new combined length and filter
  onSelectLength(event: any, length: { gte: number; lte?: number }) {
    if (event.target.checked) {
      this.clearFilter = true
      // Add if not already included
      const exists = this.selectedLengths.some(
        (l) => l.gte === length.gte * 3600 && l.lte === (length.lte ? length.lte * 3600 : undefined)
      );
  
      if (!exists) {
        this.selectedLengths.push({
          gte: length.gte * 3600,
          lte: length.lte ? length.lte * 3600 : undefined, // Convert hours to seconds
        });
      }
    } else {
      this.clearFilter = false
      // Remove if unchecked
      this.selectedLengths = this.selectedLengths.filter(
        (l) => l.gte !== length.gte * 3600 || l.lte !== (length.lte ? length.lte * 3600 : undefined)
      );
    }
  
    this.fetchFilteredVideos();
  }

  onSelectReview(ratingValue: number) {
    this.selectedReview = ratingValue;
    this.clearFilter = true
    this.fetchFilteredVideos();
  }
  

  fetchFilteredVideos() {
    const filters = {
      timeRanges: this.selectedLengths.length > 0 ? this.selectedLengths : null, // Send null if no selection
      review: this.selectedReview !== null ? this.selectedReview : null, // Send null if no selection
    };
  
    this.authService.AllhomepagevidsFilteredpg(filters).subscribe((data) => {
      this.all = data.data;
      this.nextCursor = data.nextCursor;
      this.changeDetectorRef.markForCheck();
    });
  }
  
  // new combined length and filter

  // clear filters
  getFilledStars(ratingValue: number): number {
    return Math.floor(ratingValue); // Returns the number of full stars (rounded down)
  }
  

  // clear filters not wroking correctly
  clearFilters(): void {
    this.clearFilter = false
    this.selectedLengths = []; // Clear selected length filters
    this.selectedReview = null; // Clear selected review filter
    this.nextCursor = null; // Reset pagination cursor
    this.isFetchDone = false; // Allow fetching again
    this.isLoading = true; // Show loading state
  
    // Reset checkboxes (length filters)
    const lengthCheckboxes = document.querySelectorAll('input[type="checkbox"]');
    lengthCheckboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = false; // Uncheck all checkboxes
    });
  
    // Reset radio buttons (review filters)
    const ratingRadios = document.querySelectorAll('input[type="radio"]');
    ratingRadios.forEach((radio: HTMLInputElement) => {
      radio.checked = false; // Unselect all radio buttons
    });
  
    this.loadCourses(); // Reload courses without filters
    
  }
  
  

  ngOnDestroy() {
    if (this.CourseSubscription) {
      this.CourseSubscription.unsubscribe();
    }
  }

  trackById(index: number, item: any): number | string {
    return item.id; // Return a unique identifier for each item, or use the index as a fallback
  }



  // new load
  loadCourses(): void { 
    setTimeout(() => {
      // Prepare the course filter with pagination info and any other filters (like review and time range)
      const courseFilter = {
        lastSeenId: this.nextCursor, // Pagination
        timeRanges: this.selectedLengths, // Time range filters (selected lengths)
        review: this.selectedReview || null, // Rating filter (selected review, or null if no filter is selected)
      };
  
      // Call the new API method
      this.authService.AllhomepagevidsFilteredpg(courseFilter).subscribe({
        next: (response) => {
          this.changeDetectorRef.markForCheck();
          this.all = this.all.concat(response.data);
          this.originalAll = [...this.originalAll, ...response.data];
          if (this.currentPage != 'all') {
            this.filterVideos(this.currentFilter)
          };
          this.nextCursor = response.nextCursor;
  
          if (response.nextCursor === null) {
            this.isFetchDone = true;
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.changeDetectorRef.markForCheck();
          console.error(err);
        }
      });
    }, 500);
  }
  
   // new load

  ngOnInit() {
     // new
     this.filterService.currentFilter$.subscribe(filter => {
      this.currentFilter = filter;
      if(filter != 'all'){
      this.filterVideos(filter); // Call your filtering function
      }else{
        this.resetVideos()
      }
    });
    this.authService.homepage().subscribe((profile) => { 
      this.popularInstructors = profile;
      console.log(profile + "pro")
      this.changeDetectorRef.markForCheck();
    });
    // new 
    this.breakpointObserver
      .observe(['(max-width: 576px)']) // Add more breakpoints if needed
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.itemSize = 330; // Smaller size for small screens
        } else {
          this.itemSize = 50; // Default size for larger screens
        }
        console.log(`Item Size Set To: ${this.itemSize}`); // Optional debugging
      });
    this.loadCourses()
  }

  ngAfterViewInit() {
    document.getElementById("base__zone")!.addEventListener('scroll', () => {
      const currentScrollTop = document.getElementById("base__zone")!.scrollTop;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
        if (!this.isFetchDone && !this.isLoading) {
          this.isLoading = true;
          this.loadCourses();
        }
      }
      if (currentScrollTop > this.lastScrollOffset) {
        this.isNavHidden = true;
      } else {
        this.isNavHidden = false;
      }
      this._eventService.push({
        action: EEvent.UPDATE_NAV_HIDDEN,
        payload: this.isNavHidden
      })
      this.changeDetectorRef.markForCheck();
      this.lastScrollOffset = currentScrollTop;
    })
  }

  showGlobalOverlay() {
    // this._eventService.push({
    //   action: EEvent.LATEST_URL,
    //   payload: this.router.url
    // })
    this.router.navigate(["login"])
  }


  showGlobalOverlays() {
    // let config = new OverlayConfig({
    //   hasBackdrop: true,
    //   backdropClass: 'cdk-overlay-dark-backdrop',
    //   positionStrategy: this.overlay
    //     .position()
    //     .global()
    //     .centerHorizontally()
    //     .centerVertically()
    // });

    // this.overlayRef = this.overlay.create(config);
    // this.overlayRef.attach(
    //   new ComponentPortal(
    //     RegisterComponent,
    //     this.viewContainerRefs,
    //     this.createInjector(this.overlayRef)
    //   )
    // );
    // this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
    this.router.navigate(["register"])
  }

  createInjector(overlayRef: OverlayRef): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(OverlayRef, overlayRef);
    return new PortalInjector(this.injector, injectorTokens);
  }

  createInjectors(overlayRefs: OverlayRef): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(OverlayRef, overlayRefs);
    return new PortalInjector(this.injectors, injectorTokens);
  }

  onSelectpro(newCourses) {
    var a = newCourses.profile_id;
    if (this.authService.loggedIn()) {
      this.router.navigate(['/profile', a]);
    } else {
      this.showGlobalOverlay();
    }
  }

  onSelect(newCourses) {
    var a = newCourses.course;
    this.router.navigate(['/video', a]);
  }

  onSelects(item) {
    var b = item.id;
    this.router.navigate(['/video', b]);
  }

  onCategory(popularInstructors) {
    var b = popularInstructors.route;
    this.router.navigate(['/' + b + '/1']);
  }

  toggleShowFilter() {
    this.showFilter = !this.showFilter;
    this.changeDetectorRef.markForCheck();
  }
  // new
  setType(type: string) {
    this.currentFilterType = type
    if(type != "all") {
      this.searchFilter(type)
    }else{
      this.resetVideos()
    }
   
  }
  // new
  
  filterDialog() {
   
    this.openFilter = !this.openFilter
   
  }

  searchFilter(search){

    var id = {
    
      search: search
    };
  
  
     this.authService.Searchfilter2(id).subscribe((response) => {  
      // this.all = data.data;
      this.all = response.data
      // this.originalAll = [...this.originalAll, ...response.data];
      // this.nextCursor = data.nextCursor;
      // this.totalRecords = data.totalDocs;
      this.changeDetectorRef.markForCheck();
    });
  
    }

   
  
  

}