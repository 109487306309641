import { Injectable } from '@angular/core';
// import {Http, Response, Headers } from '@angular/http';

// import{HttpParams} from'@angular/common/http';
// import { map } from 'rxjs/operators';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/do';
import { Observable } from 'rxjs';
// import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import {tokenNotExpired} from '@auth0/angular-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Course } from '../classes/course.model';
// import { ICountry } from '../classes/country';
// import { IComment } from '../classes/comment';
// import { newCourse } from '../classes/newCourse';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authToken: any;
  user: any;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService
    // private http:Http,
  ) {}

  //   getpresignedurls(logNamespace, fileType) {
  //   let getheaders = new HttpHeaders().set('Accept', 'application/json');
  //   let params = new HttpParams().set('fileName', logNamespace).set('fileType', fileType);
  //   return this.http.get<any>('/api2/generatepresignedurl', { params: params, headers: getheaders });
  // }

  storeUserData(token, user) {
    if (typeof window !== 'undefined') {
      localStorage.setItem('id_token', token);
      localStorage.setItem('user', JSON.stringify(user));
      this.authToken = token;
      this.user = user;
    }
  }

  loadToken() {
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('id_token');
      this.authToken = token;
    }
  }

  //  loggedIn(){
  //   if (typeof window !== 'undefined') {
  //    return tokenNotExpired('id_token');
  //   }
  //  }

  loggedIn() {
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('id_token'); // Adjust this if your token is stored under a different key
      // Check if the token exists and is not expired
      return token != null && !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  logout() {
    if (typeof window !== 'undefined') {
      this.authToken = null;
      this.user = null;
      localStorage.clear();
    }
  }

  //login component

  //  authenticateUser(user){
  //   let headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   return this.http.post('/api2/authenticate', user,{headers: headers})
  //  .pipe(map((response: any) => response));
  // }

  authenticateUser(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/authenticatepg', data, { headers });
  } 
  authenticateUserGoogle(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/authenticatepg', data, { headers });
  } 
  resetPassword(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/forgotpg', data, { headers });
  }
  // resetPassword(user){
  //   let headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   return this.http.post('/api2/forgot', user,{headers: headers})
  //   .pipe(map((response: any) => response));
  // }

  // updateUsertok(user){
  //   let headers = new HttpHeaders();
  //   headers.append('Content-type', 'application/json');
  //   return this.http.put('/api2/updateUsertok' , user,{headers: headers})
  //   .pipe(map((response: any) => response));
  // }

  updateUsertok(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('api2/updateUsertokpg', data, { headers });
  }

  UpdatePassword(user: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('api2/resetpg', user, { headers });
  }
  


  // registerUser(user){
  //   let headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   return this.http.post('/api2/register', user,{headers: headers})
  //   .pipe(map((response: any) => response));
  // }
  registerUser(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/registerpg', data, { headers });
  }

  //login and register component

  // home component

  // Allhomepagevids(id){

  //   let headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   return this.http.post('/api2/Allhomepagevids',id, {headers: headers})
  //   .pipe(map((response: any) => response));
  // }

  Allhomepagevids(data: any): Observable<any> { 
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/Allhomepagevidspg', data, { headers });
  }  
 

  AllhomepagevidsFilteredpg(data: any): Observable<any> { 
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/AllhomepagevidsFilteredpg', data, { headers });
  }  
 
  // getProfile(){
  //   let headers = new HttpHeaders();
  //   this.loadToken();
  //   headers.append('Authorization', this.authToken);
  //   headers.append('Content-Type', 'application/json');
  //   return this.http.get('/api2/profile',{headers: headers})
  //   .pipe(map((response: any) => response));

  // }
  getProfile() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.get<any>('/api2/profilepg', { headers: headers });
  } 

  // updateUserProfile(user){
  //   let headers = new HttpHeaders();
  //   this.loadToken();
  //   headers.append('Authorization', this.authToken);
  //   headers.append('Content-type', 'application/json');
  //   return this.http.put('/api2/updateUserProfile' , user,{headers: headers})
  //   .pipe(map((response: any) => response));
  // }

  updateUserProfile(user: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.put<any>('/api2/updateUserProfilepg', user, {
      headers: headers
    });
  }

  // homecomponent

  // navbar

  // Stats(id){
  //   let headers = new HttpHeaders();
  //   this.loadToken();
  //   headers.append('Authorization', this.authToken);
  //   headers.append('Content-type', 'application/json');
  //   return this.http.post('/api2/Stats', id,  {headers: headers})
  //   .pipe(map((response: any) => response));
  // }

  Stats(id: any): Observable<any> {   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/Statspg', id, { headers: headers });
  }

  homepage() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<any>('/api2/getFirst10Userspg', { headers });
  } 

  recommends(id:number){  
    
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<any>('/api2/getFirst5recvids/' + id, { headers });
  } 

  getNotifications() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);
    return this.http.get<any>('/api2/notifications', { headers });
  }

  getFollowers(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/getFollowers/' + id, { headers });
  }

  getfollowing(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/getfollowing/' + id, { headers });
  }

  //navbar

  registerStripeAccountId(data: any) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    // return this.http.put<any>('/api/stripeAccountId' , data,{headers: headers})
    this.loadToken();
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.put<any>('/api2/stripeAccountIdpg', data, {
      headers: headers
    });
  }

  postSalesAnalytics(info) {  
    this.loadToken();

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/postSalesAnalytics2', info, {
      headers: headers
    });
  } 

  postMembershipSales(info) {  
    this.loadToken();

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/membershipSales2', info, {
      headers: headers
    });
  }

  postCustomer(info) {  
    this.loadToken();

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/newcustomers', info, {
      headers: headers
    });
  }

  // category component

  getAllDevelopment(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/AllDevelopment', id, { headers });
  }

  getAllPrivateHome(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/AllPrivateHome', id, { headers });
  }

  Searchfilter(id: any): Observable<any> { 
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/Searchfilterpg', id, { headers });
  } 
  Searchfilter2(id: any): Observable<any> { 
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/Searchfilterpg2', id, { headers });
  } 
  SubscribedUsers(id: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/SubscribedUserspg', id, {
      headers: headers
    });
  } 

  SubscribedVids(id: any): Observable<any> { 
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/SubscribedVidspg', id, {
      headers: headers
    });
  }
  PurchasedVids(id: any): Observable<any> {   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/PurchasedVidspg', id, { headers: headers });
  }   

  PurchasedSubsVids(id: any): Observable<any> {   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/PurchasedSubpg', id, { headers: headers });
  }   

  MySubscriptions(): Observable<any> {  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.get<any>('/api2/MySubscriptions', { headers: headers });
  }  

  MyPaidSubscriptions(): Observable<any> { 
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.get<any>('/api2/MyPaidSubscriptions', {
      headers: headers
    });
  }
  MyPaidSubscriptionsAll(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.get<any>('/api2/MyPaidSubscriptionsAll', {
      headers: headers
    });
  } 

  MyNotActiveSubscriptionsAll(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.get<any>('/api2/MyNotActiveSubscriptionsAll', {
      headers: headers 
    });
  } 

  cancelSubscription(subscriptionId: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/cancelSubscription/' + subscriptionId, {
      headers
    });
  }

  renewMembership(subscriptionId: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/renewMembership/' + subscriptionId, {
      headers
    });
  }

  getUser(membershipUserId: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/userpg/' + membershipUserId, { headers });
  }

  MyPurchases(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.get<any>('/api2/MyPurchases', { headers: headers });
  } 

  Purchases(id: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/Purchases', id, { headers: headers });
  }

  help(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('/api2/help', data, { headers });
  }

  HistoryVids(id: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/HistoryVidspg', id, { headers: headers });
  }  
  MyHistory(): Observable<any> { 
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.get<any>('/api2/MyHistory', { headers: headers });
  }

  //profile
  AllProfilevids(id): Observable<any> { 
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/AllProfilevidspg', id, {
      headers: headers
    });
  }   

  newMyMessage(message: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('/api2/newMyMessage', message, { headers });
  } 

  deleteSubscription(id: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.delete<any>('/api2/deleteSubscription/' + id, {
      headers: headers
    });
  }

  deleteFollowing(id: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.delete<any>('/api2/deleteFollowing/' + id, {
      headers: headers
    });
  }

  deleteFollower(id: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.delete<any>('/api2/deleteFollower/' + id, {
      headers: headers
    });
  }

  Subscribe(id: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.put<any>('/api2/Subscribe', id, { headers: headers });
  }

  AddFollowers(followers: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.put<any>('/api2/AddFollowers', followers, {
      headers: headers
    });
  }

  AddFollowing(following: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.put<any>('/api2/AddFollowing', following, {
      headers: headers
    });
  }

  checkIfFollowing(id: any): Observable<any> { 
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.get<any>('/api2/checkIfFollowing/' + id, {
      headers: headers
    });
  }

  profilepage(id: any): Observable<any> {  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.get<any>('/api2/profilepagepg/' + id, { headers: headers });
  } 

  profilePageName(id: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.get<any>('/api2/profilepageNamepg/' + id, { headers: headers });
  } 
  //

  getName(name: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/getNamepg/' + name, { headers });
  } 

  getPageName(name: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/getPageNamepg/' + name, { headers }); 
  }

  getMymessage(id: any): Observable<any> { 
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<any>('/api2/getMymessage/' + id, { headers });
  }

  getChat(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<any>('/api2/getChat/' + id, { headers });
  }
  getMessage(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<any>('/api2/getMessage/' + id, { headers });
  }

  sendChat(chat: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.put<any>('/api2/Chats', chat, { headers: headers });
  }

  deleteMessage(id: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.delete<any>('/api2/deleteMessage/' + id, {
      headers: headers
    });
  }
  deleteChat(id: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.delete<any>('/api2/deleteChat/' + id, {
      headers: headers
    });
  }
  // category component

  // video component
  setHeart(heart: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.put<any>('/api2/courseHeart', heart, { headers: headers });
  }

  setStar(review: any): Observable<any> { 
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.put<any>('/api2/courseRating', review, {
      headers: headers
    });
  }

  Commenthearts(heart: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('/api2/CommentLikes', heart, { headers });
  }

  CommentReplyhearts(heart: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('/api2/CommentReplyLikes', heart, { headers });
  }

  getCommentReplys(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<any>('/api2/commentReply/' + id, { headers });
  }

  postNotifications(comment: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.put<any>('/api2/myNotification', comment, {
      headers: headers
    });
  }

  deleteComment(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.delete<any>('/api2/deleteComment/' + id, { headers });
  }

  deleteAllCommentReplies(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.delete<any>('/api2/deleteAllCommentReplies/' + id, {
      headers
    });
  }

  decreaseReplies(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('/api2/decreaseReplies/' + id, { headers });
  }

  deleteCommentReply(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.delete<any>('/api2/deleteCommentReply/' + id, { headers });
  }

  getComments(comment: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('/api2/comment', comment, { headers });
  }

  gethighlightedReply(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<any>('/api2/highlightedReply/' + id, { headers });
  }

  getPurchased(id: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.get<any>('/api2/Purchased/' + id, { headers: headers });
  }
  getMembership(planIds: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    headers = headers.append('Authorization', this.authToken);

    return this.http.post<any>('/api2/Membership', planIds, {
      headers: headers
    });
  }

  //   getMembership(planIds: string[]): Observable<any> {
  //     let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //     this.loadToken();

  //     // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
  //     headers = headers.append('Authorization', this.authToken);

  //     // Convert the planIds array to a comma-separated string
  //     const planIdsString = planIds.join(',');

  //     return this.http.get<any>(`/api2/Membersip/${planIdsString}`, { headers: headers });
  // }

  Viewshomenew(view: any): Observable<any> { 
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 

    return this.http.post<any>('/api2/Viewshomenewpg', view, { headers });
  }  

  History(id: any): Observable<any> {  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.put<any>('/api2/History', id, { headers: headers });
  }

  getReviews(courses: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<any>('/api2/rating/' + courses, { headers });
  }

  updateNewCourseview(uploads: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
    this.loadToken();

    // Ensure the new instance of HttpHeaders with the appended Authorization header is captured
    headers = headers.append('Authorization', this.authToken);

    return this.http.put<any>('/api2/updateNewCourseviewpg', uploads, {
      headers: headers
    });
  }     
 
  postCommentReply(myComment: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('/api2/myCommentReply', myComment, { headers });
  }

  Replies(reply: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('/api2/replies', reply, { headers });
  }

  postcomment(myComment: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('/api2/myComment', myComment, { headers });
  }

  getHoms(lecture) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>('/api2/homs', lecture, { headers });
  }

  getHoms2(id) {  
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<Course>('/api2/homs2pg/' + id, { headers });
  }
  
  Updatereview(id: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('/api2/Updatereviewpg', id, { headers });
  }
  UpdateHomereview(ids: any): Observable<any> { 
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>('/api2/UpdateHomereview', ids, { headers });
  }

  // video component
}
