<div  class="dialogy">
<h3>
  {{ fetch }}
  <h3>
    <hr />
    <div *ngFor="let all of all" class="userInfo">
      <div *ngIf="fetch === 'followers'" class="userInfo__left">
        <mat-card style="display: none"></mat-card>
        <img
          class="header6Option__iconno"
          mat-card-avatar
          src="{{ all.follower.profilePic }}"
          onerror="this.src='https://www.startupschool.org/avatar.svg'"
        />
        <h5 *ngIf="fetch === 'followers'">{{ all.follower.name }}</h5>
      </div>

      <div *ngIf="fetch === 'following'" class="userInfo__left">
        <mat-card style="display: none"></mat-card>
        <img
          class="header6Option__iconno"
          mat-card-avatar
          src="{{ all.following.profilePic }}"
          onerror="this.src='https://www.startupschool.org/avatar.svg'"
        />
        <h5>{{ all.following.name }}</h5>
      </div>

      <div *ngIf="fetch === 'followers'; else elseBlocky">
        <button
          (click)="subscribes(all.follower.name)"
          class="button"
          mat-button
        >
          <!-- <mat-icon> </mat-icon> -->
          view
        </button>
      </div>
      <ng-template #elseBlocky>
        <button
          (click)="subscribes(all.following.name)"
          class="button"
          mat-button
        >
          <!-- <mat-icon> </mat-icon> -->
          view
        </button>
      </ng-template>
    </div>
  </h3>
</h3>
</div>