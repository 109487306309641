import { Injectable } from '@angular/core';
import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch';

@Injectable({
    providedIn: 'root',
})
export class AlgoliaService {
    private client: SearchClient;
    private index: SearchIndex;

    constructor() {
        this.client = algoliasearch('NC39OBLGG3', '1860fc0a8d6d08d96da87f766b1bb47b');
        this.index = this.client.initIndex('Homenew');
    }

    search(query: string) {
        return this.index.search(query);
    }
}
