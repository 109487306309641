import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Injector,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as io from 'socket.io-client';
import { AuthService } from '../../services/auth.service';
import { FlashMessageService } from '../../services/flashmessages.service';
import { FollowersComponent } from './followers/followers.component';
import { StatsComponent } from './stats/stats.component';
import { FilterService } from '../../services/filter.service';
import { AlgoliaService } from 'src/app/services/algolia.service';
// import { EEvent, EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawer: MatDrawerContainer;
  @Output() changeRoute = new EventEmitter();

  currentFilterType = 'all'; // Track the active filter

  connectedAccountId: string;
  notifications: any;
  no: boolean = false;
  socket;
  showoverlay: boolean = false;
  showside: boolean = true;
  over: boolean = false;
  hide: boolean = false;

  loading: boolean = false;
  form: boolean = false;
  search: string;
  id: string;
  searchPageName: string;
  overlayRefs: OverlayRef;
  overlayRef: OverlayRef;
  nextPosition: number = 0;
  isMenuOpen: boolean = false;
  home: boolean = true
  show: boolean = true;
  sho: boolean = true;
  development: boolean = false;
  business: boolean = false;
  finance: boolean = false;
  it: boolean = false;
  of: boolean = false;
  pd: boolean = false;
  de: boolean = false;
  mar: boolean = false;
  li: boolean = false;
  ph: boolean = false;
  hf: boolean = false;
  Mu: boolean = false;
  teach: boolean = false;
  user: any;
  firstlet: string;
  profile: string;
  totalFollowers: any;
  totalFollowing: any;
  Followers: any;
  Following: any;
  popularInstructors: any;
  avgRating: any;
  // Userinfo:any;
  Usermessage: string;
  Usernotification: string;
  // userId:string;
  public toggled = false;
  mobile: Observable<BreakpointState> =
    this.breakpointObserver.observe('(max-width: 599px)');
  tablet: Observable<BreakpointState> =
    this.breakpointObserver.observe('(max-width: 600px)');
  // tablet2: Observable<BreakpointState> =
  //   this.breakpointObserver.observe('(max-width: 769px)');
  // lgtablet: Observable<BreakpointState> =
  //   this.breakpointObserver.observe('(max-width: 891px)');
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(
    '(max-width: 1150px)'
  );
  isLargeScreen$: Observable<boolean>;
  dataAlgolia: any[] = []

  constructor(
    public overlay: Overlay,
    public overlays: Overlay,
    public viewContainerRefs: ViewContainerRef,
    private injectors: Injector,
    private injector: Injector,
    private breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    public router: Router,
    private flashMessage: FlashMessageService,
    private location: Location,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private filterService: FilterService,
    private _algoliaService: AlgoliaService
    // private _eventService: EventService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.router.url != '/') {
          this.showside = false;
          this.over = true;
          this.hide = true;

        } else {
          this.showside = true;
          this.over = false;
          this.hide = false;
        }
        this.changeDetectorRef.markForCheck();
        // if (this.isHandset) {
        //
        //   // this.drawer.close();
        //
        //   }
      });

    if (typeof window !== 'undefined') {
      this.loading = true;
      this.socket = io();
    }

    if (typeof window === 'undefined') {
      this.loading = false;
    }
  }

  // new
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    setTimeout(() => {
      if (!this.hide && this.router.url == '/') {
        if (window.innerWidth > 1440) {
          this.showside = true
        } else {
          this.showside = false;
        }
      }
      this.changeDetectorRef.markForCheck();
    }, 20);
  }

  filterVideos(type: string) {
    this.currentFilterType = type;
    this.filterService.setFilter(type); // Send filter to service
    this.changeDetectorRef.markForCheck();
  }
  // new



  onSelectpro(user) {
    if (this.authService.loggedIn()) {
      this.router.navigate([`p/${user}`]);
    } else {
      this.showGlobalOverlay();
    }
  }



  public FollowersDialog(a) {
    var width = '35%';
    var height = '55%';

    let dialogRef = this.dialog.open(FollowersComponent, {
      width: width,
      height: height,
      data: {
        fetch: a,
        followers: this.Followers,
        following: this.Following
      }
    });
  }

  public StatsDialog() {
    var width = '35%';
    var height = '55%';

    let dialogRef = this.dialog.open(StatsComponent, {
      width: width,
      height: height,
      data: {
        avgRating: this.avgRating
      }
    });
  }

  getStats(id) {
    var ids = {
      page: 1,
      id: id
    };
    this.authService.Stats(ids).subscribe((data) => {
      try {
        this.avgRating = data[0].averageReview
        // this.avgRating = data.docs[0].pop;  
      } catch (error) {
        console.log('null');
      } finally {
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  ngOnInit() {

    this.isLargeScreen$ = this.breakpointObserver.observe(['(min-width: 1450px)'])
      .pipe(
        map(result => result.matches) // If the condition is met, showside will be true
      );

    // Subscribe to the observable to update showside value based on the screen size
    this.isLargeScreen$.subscribe(isLarge => {
      this.showside = isLarge;
    });

    var id = {
      page: 1
    };

    // this.authService.Stats(id).subscribe((data) => {
    //   try {
    //     this.avgRating = data.docs[0].pop;
    //     this.changeDetectorRef.markForCheck();
    //   } catch (error) {
    //     console.log('null');
    //   }
    // });

    this.authService.homepage().subscribe((profile) => {
      this.popularInstructors = profile;
      this.changeDetectorRef.markForCheck();
    });



    // this.flashMessage.display('Teachers Day Sale | All Courses $1.99. Shop our biggest sale of the season through May. 25', 3000); // Displays for 5000 ms (5 seconds)

    this.authService.getProfile().subscribe(
      (profile) => {
        try {
          this.connectedAccountId = profile.connectedAccountId;
          this.Usermessage = profile.user.message;
          this.Usernotification = profile.user.notification;
          this.user = profile.user.name;
          this.profile = profile.user.profile;
          //postgres
          this.id = profile.user.id;
          this.searchPageName = profile.user.searchPageName;

          // this.id = profile.user._id;
          var x = profile.user.name;
          this.firstlet = x.charAt(0).toUpperCase();
          this.getStats(profile.user.id)
        } catch (error) {
          console.log('null');
        } finally {
          this.changeDetectorRef.markForCheck();
        }

        this.authService.getNotifications().subscribe((notifications) => {
          this.notifications = notifications.comment;

          try {
            notifications.comment[0].comments.profile;
            this.no = false;
          } catch (error) {
            this.no = true;
            console.log('null');
          } finally {
            this.changeDetectorRef.markForCheck();
          }
        });

        this.authService.getFollowers(profile.user.id).subscribe((data) => {
          try {
            this.Followers = data.docs;
            this.totalFollowers = data.totalDocs;
            this.changeDetectorRef.markForCheck();
          } catch (error) {
            console.log('null');
          } finally {
            this.changeDetectorRef.markForCheck();
          }
        });

        this.authService.getfollowing(profile.user.id).subscribe((data) => {
          try {
            this.Following = data.docs;
            this.totalFollowing = data.totalDocs;
            this.changeDetectorRef.markForCheck();
          } catch (error) {
            console.log('null');
          } finally {
            this.changeDetectorRef.markForCheck();
          }
        });
      },
      (err) => {
        return false;
      }
    );

    this.socket.on('login', () => {
      this.authService.getProfile().subscribe(
        (profile) => {
          try {
            this.connectedAccountId = profile.connectedAccountId;
            this.Usermessage = profile.user.message;
            this.Usernotification = profile.user.notification;
          } catch (error) {
            console.log('null');
          } finally {
            this.changeDetectorRef.markForCheck();
          }
          // this.Userinfo = profile.user
          this.user = profile.user.name;
          this.profile = profile.user.profile;

          //postgres
          this.id = profile.user.id;

          // this.id = profile.user._id;
          var x = profile.user.name;
          this.firstlet = x.charAt(0).toUpperCase();
          this.changeDetectorRef.markForCheck();
          this.authService.getNotifications().subscribe((notifications) => {
            this.notifications = notifications.comment;
            try {
              notifications.comment[0].comments.profile;
              this.no = false;
            } catch (error) {
              this.no = true;
              console.log('null');
            } finally {
              this.changeDetectorRef.markForCheck();
            }
          });

          this.authService.getFollowers(profile.user.id).subscribe((data) => {
            try {
              this.Followers = data.docs;
              this.totalFollowers = data.totalDocs;
            } catch (error) {
              console.log('null');
            } finally {
              this.changeDetectorRef.markForCheck();
            }
          });

          this.authService.getfollowing(profile.user.id).subscribe((data) => {
            try {
              this.Following = data.docs;
              this.totalFollowing = data.totalDocs;
            } catch (error) {
              console.log('null');
            } finally {
              this.changeDetectorRef.markForCheck();
            }
          });
        },
        (err) => {
          console.log(err);
          return false;
        }
      );
    });
  }

  forms() {
    // setTimeout(()=>{ this.form = true }, 600)
  }

  showGlobalOverlay() {

    this.router.navigate(["login"])
  }
  showGlobalOverlays() {
    // if (this.mobile) {
    //   var width = '270px';
    //   var height = '400px';
    // } else {
    //   var width = '370px';
    //   var height = '450px';
    // }
    // let config = new OverlayConfig({
    //   hasBackdrop: true,
    //   backdropClass: 'cdk-overlay-dark-backdrop',
    //   positionStrategy: this.overlay
    //     .position()
    //     .global()
    //     .centerHorizontally()
    //     .centerVertically()
    //   // .height(height)
    //   // .width(width)
    // });

    // this.overlayRef = this.overlay.create(config);
    // this.overlayRef.attach(
    //   new ComponentPortal(
    //     RegisterComponent,
    //     this.viewContainerRefs,
    //     this.createInjectors(this.overlayRef)
    //   )
    // );
    // this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
    this.router.navigate(["register"])
  }

  createInjector(overlayRef: OverlayRef): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(OverlayRef, overlayRef);
    return new PortalInjector(this.injector, injectorTokens);
  }

  createInjectors(overlayRefs: OverlayRef): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(OverlayRef, overlayRefs);
    return new PortalInjector(this.injectors, injectorTokens);
  }

  Search() {
    this.showoverlay = false;
    this.changeDetectorRef.markForCheck();

    this.router.navigate(['/search'], {
      queryParams: { search: this.search, page: '1' }
    });

    this.search = null;
    this.changeDetectorRef.markForCheck();
  }

  fetchData() {
    this.dataAlgolia = []
    if (this.search.trim().length > 0) {
      this._algoliaService.search(this.search).then(res => {
        this.dataAlgolia = res.hits
        // res.hits.forEach(hit => {
        //   if (hit._highlightResult["search"].some(term => term["matchedWords"].length > 0)) {
        //     let index = hit._highlightResult["search"].findIndex(term => term["matchedWords"].length > 0)
        //     if (!this.dataAlgolia.some(x => x.value == hit._highlightResult["search"][index].value)) {
        //       this.dataAlgolia = this.dataAlgolia.concat(hit._highlightResult["search"][index])
        //     }
        //   }
        // });
      }).finally(() => this.changeDetectorRef.markForCheck())
    }
  }

  gotoSearch(value: string) {
    this.dataAlgolia = []
    this.showoverlay = false;
    this.search = null;
    this.changeDetectorRef.markForCheck();

    this.router.navigate(['/search'], {
      queryParams: { search: value, page: '1' }
    });
  }

  onNotificationCheck() {
    this.Usernotification = 'false';
    const user = {
      recieverid: this.id,
      notification: 'false'
    };

    this.changeDetectorRef.markForCheck();

    this.authService.updateUserProfile(user).subscribe((profile) => { });
  }

  onUpload() {
    if (this.authService.loggedIn()) {
      // this.drawer.close();

      if (this.connectedAccountId) {
        this.router.navigate(['/studio/user']);
      } else {
        this.router.navigate(['/studio/creator']);
      }
    } else {
      this.showGlobalOverlay();
    }
  }

  Creators() {
    if (this.authService.loggedIn() && this.id) {

      this.router.navigate(['/creators']);
    } else {
      this.showGlobalOverlay();
    }
  }

  onSelectNew(a) {
    if (a === 'messages') {
      this.Usermessage = 'false';
      const user = {
        recieverid: this.id,
        message: 'false'
      };

      this.changeDetectorRef.markForCheck();

      this.authService.updateUserProfile(user).subscribe((profile) => {
        // console.log(profile);
      });
    }

    if (this.authService.loggedIn()) {
      // this.drawer.close();

      this.router.navigate(['/' + a]);
    } else {
      this.showGlobalOverlay();
    }
  }

  HighlightedComment(notifications) {
    var a = notifications.comments.route;
    // ^goood
    var status = notifications.comments.status;
    // ^goood
    var comment_id = notifications.comments.comment_id;

    var reply_id = notifications.comments.reply_id;

    if (!reply_id) {
      this.router
        .navigate(['/video', a], {
          queryParams: { v: comment_id }
        })
        .then(() => {
          this.changeRoute.emit(a);
        });
    } else {
      var reply_id = notifications.comments.reply_id;

      this.router
        .navigate(['/video', a], {
          queryParams: { v: comment_id, v2: reply_id }
        })
        .then(() => {
          this.changeRoute.emit(a);
        });
    }
  }

  onLogoutClick() {
    this.authService.logout();
    this.flashMessage.display('You are logged out', 'success', 2000);

    return false;
  }

  toggleShowOverlay() {
    this.showoverlay = !this.showoverlay;
    this.changeDetectorRef.markForCheck();
  }



  toggleShowside() {
    this.showside = !this.showside;
    this.changeDetectorRef.markForCheck();
  }

  setType(type: string) {
    this.currentFilterType = type
  }

  action(drawer) {
    if (window.innerWidth > 1024) {
      this.toggleShowside()
    } else {
      drawer.toggle()
    }
  }

  // new
  checkURL() {
    return (this.router.url.includes('register') || this.router.url.includes('login'))
  }
}