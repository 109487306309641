<section class="signin__container">
    <div class="signin__content">
        <div class="signin">
            <div class="signin__logo">
                <img [routerLink]="['/']" class="header__logo pointer"
                    src="https://s3bucket190.s3.us-east-1.amazonaws.com/download__4___2_-removebg-preview%2B(1).png" />
                <span class="header__Vlogmo">Vlogmo</span>
            </div>
            <div class="signin__title">
                {{isForgotPassword ? 'Reset Password' : 'Sign In'}}
            </div>
            <div class="signin__form">
                <div class="signin__form__field">
                    <span class="signin__form__label">Email</span>
                    <i class="material-icons email">
                        mail
                    </i>
                    <input type="email" class="signin__form__input input__email" [(ngModel)]="email"
                        placeholder="Enter your email">
                </div>
                <div class="signin__form__field" *ngIf="!isForgotPassword">
                    <span class="signin__form__label">Password</span>
                    <input [type]="showpassword ? 'text' : 'password'" class="signin__form__input"
                        [(ngModel)]="password" placeholder="Enter your password">
                    <i class="material-icons toggle__password" (click)="toggleShowPassword()">
                        {{showpassword ? 'visibility' : 'visibility_off'}}
                    </i>
                </div>
                <div class="signin__forgotpassword" (click)="forgotPassword()">
                    {{isForgotPassword ? 'Login' : 'Forgot Password?'}}
                </div>
                <div class="btn__signin" [class.opacity]="checkValidForm()" (click)="login()">
                    {{isForgotPassword ? 'Confirm' : 'Sign In'}}
                </div>
            </div>
            <div class="signin__notice">
                Don't have an account? <span (click)="register()">Sign Up</span>
            </div>
            <div class="or__option">
                <div class="line"></div>
                <span>OR</span>
                <div class="line"></div>
            </div>
            <!-- <div class="login__social">
                <img src="../../../assets/img/logo-google.svg">
                <span>Continue with Google</span>
            </div> -->
            <!-- <div class="login__social mt-2">
                <img src="../../../assets/img/logo-apple.svg">
                <span>Continue with Apple</span>
            </div> -->
        </div>

        <div  id="google-signin-btn"></div> 

    </div>
</section>