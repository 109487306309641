import { NgModule } from '@angular/core';
import { HomeComponent } from '../app/components/home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './routernav/main/main.component';
import { Authguard } from '../app/services/authguard.service';
import { RegisterComponent } from './components/register/register.component';
// import { RegisterSuccessComponent } from './components/register-success/register-success.component';
import { LoginComponent } from './components/login/login.component';


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'video/:id',
        loadChildren: () =>
          import('./components/video/video.module').then((m) => m.VideoModule)
      },
      {
        path: 'subscribed',
        loadChildren: () =>
          import('./components/subscribed/subscribed.module').then(
            (m) => m.SubscribedModule
          ),
        canActivate: [Authguard]
      },
      {
        path: 'feed/manage',
        loadChildren: () =>
          import(
            './components/managesubscription/managesubscription.module'
          ).then((m) => m.ManagesubscriptionModule)
      },
      {
        path: 'purchased',
        loadChildren: () =>
          import('./components/purchased/purchased.module').then(
            (m) => m.PurchasedModule
          ),
        canActivate: [Authguard]
      },
      {
        path: 'help',
        loadChildren: () =>
          import('./components/help/help.module').then((m) => m.HelpModule)
      },
      {
        path: 'history',
        loadChildren: () =>
          import('./components/history/history.module').then(
            (m) => m.HistoryModule
          )
      },
      {
        path: 'p/:pageName',
        loadChildren: () =>
          import('./components/profile/profile.module').then(
            (m) => m.ProfileModule
          )
      },

      {
        path: 'search',
        loadChildren: () =>
          import('./components/search/search.module').then(
            (m) => m.SearchModule
          ),
      },
      {
        path: 'creators',
        loadChildren: () =>
          import('./components/creators/creators.module').then(
            (m) => m.CreatorsModule
          ),
      },
      {
        path: 'category/development/:id',
        loadChildren: () =>
          import('./components/category/development/development.module').then(
            (m) => m.DevelopmentModule
          ),
        canActivate: [Authguard]
      },
      {
        path: 'category/web-development/:id',
        loadChildren: () =>
          import(
            './components/category/web-development/web-development.module'
          ).then((m) => m.WebDevelopmentModule),
        canActivate: [Authguard]
      },
      {
        path: 'category/mobile-apps/:id',
        loadChildren: () =>
          import('./components/category/mobile-apps/mobile-apps.module').then(
            (m) => m.MobileAppsModule,
          ),
        canActivate: [Authguard]
      },
      {
        path: 'category/game-development/:id',
        loadChildren: () =>
          import(
            './components/category/game-development/game-development.module'
          ).then((m) => m.GameDevelopmentModule),
        canActivate: [Authguard]
      },
      {
        path: 'category/database/:id',
        loadChildren: () =>
          import('./components/category/database/database.module').then(
            (m) => m.DatabaseModule
          ),
        canActivate: [Authguard]
      },
      {
        path: 'category/business/:id',
        loadChildren: () =>
          import('./components/category/business/business.module').then(
            (m) => m.BusinessModule
          ),
        canActivate: [Authguard]
      },
      {
        path: 'category/it-software/:id',
        loadChildren: () =>
          import('./components/category/it-software/it-software.module').then(
            (m) => m.ItSoftwareModule
          ),
        canActivate: [Authguard]
      }
    ]
  },
  {
    path: '',
    canActivate: [Authguard],
    children: [
      {
        path: 'messages',
        loadChildren: () =>
          import('./components/messages/messages.module').then(
            (m) => m.MessagesModule
          ),
        canActivate: [Authguard]
      },
      {
        path: 'messages/:id',
        loadChildren: () =>
          import('./components/messagesid/messages.module').then(
            (m) => m.MessagesidModule
          ),
        canActivate: [Authguard]
      },
      {
        path: 'edit',
        loadChildren: () =>
          import('./components/edit/edit.module').then(
            (m) => m.EditModule
          )
      },
      {
        path: 'resetpassword/:id',
        loadChildren: () =>
          import('./components/resetpassword/resetpassword.module').then(
            (m) => m.ResetpasswordModule
          )
      },
      {
        path: 'payment/:id',
        loadChildren: () =>
          import('./components/paymentpage/paymentpage.module').then(
            (m) => m.PaymentpageModule
          )
      },
      {
        path: 'donations',
        loadChildren: () =>
          import('./components/donations/donations.module').then(
            (m) => m.DonationsModule
          )
      },
      {
        path: 'subscription',
        loadChildren: () =>
          import(
            './components/subscriptionpayment/subscriptionpayment.module'
          ).then((m) => m.SubscriptionpaymentModule)
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import(
            './components/subscriptionpaymentprofile/subscriptionpayment.module'
          ).then((m) => m.SubscriptionpaymentprofileModule)
      },
      {
        path: 'memberships/:id',
        loadChildren: () =>
          import('./components/memberships/memberships.module').then(
            (m) => m.MembershipsModule
          )
      },
      {
        path: 'success',
        loadChildren: () =>
          import('./components/purchasesuccess/purchasesuccess.module').then(
            (m) => m.PurchasesuccessModule
          )
      },
      {
        path: 'failed/:id',
        loadChildren: () =>
          import(
            './components/purchasecancelled/purchasecancelled.module'
          ).then((m) => m.PurchasecancelledModule)
      }
    ]
  },
  {
    path: 'studio',
    canActivate: [Authguard],
    children: [
      {
        path: 'user',
        loadChildren: () =>
          import('./components/studio/user/user.module').then(
            (m) => m.UserModule
          )
      },
      {
        path: 'creator',
        loadChildren: () =>
          import(
            './components/studio/creatoroverlay/creatoroverlay.module'
          ).then((m) => m.CreatoroverlayModule)
      },
      {
        path: 'analytics/membership',
        loadChildren: () =>
          import(
            './components/studio/analytics/membership/membership.module'
          ).then((m) => m.MembershipModule)
      },
      {
        path: 'payout',
        loadChildren: () =>
          import('./components/studio/payout/payout.module').then(
            (m) => m.PayoutModule
          )
      },
      {
        path: 'onboardingcomplete',
        loadChildren: () =>
          import(
            './components/studio/onboardingcomplete/onboardingcomplete.module'
          ).then((m) => m.OnboardingcompleteModule)
      },
      {
        path: 'earnings',
        loadChildren: () =>
          import('./components/studio/earnings/earnings.module').then(
            (m) => m.EarningsModule
          )
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./components/studio/settings/settings.module').then(
            (m) => m.SettingsModule
          )
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('./components/studio/messages/messages.module').then(
            (m) => m.Messages2Module
          )
      },
      {
        path: 'messages/:id',
        loadChildren: () =>
          import('./components/studio/messagesid/messages.module').then(
            (m) => m.Messagesid2Module
          )
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./components/studio/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          )
      },
      {
        path: 'analytics/earnings',
        loadChildren: () =>
          import('./components/studio/analytics/earnings/earnings.module').then(
            (m) => m.EarningsModule
          )
      },
      {
        path: 'analytics/purchases',
        loadChildren: () =>
          import(
            './components/studio/analytics/purchases/purchases.module'
          ).then((m) => m.PurchasesModule)
      },
      {
        path: 'analytics/traffic',
        loadChildren: () =>
          import('./components/studio/analytics/traffic/traffic.module').then(
            (m) => m.TrafficModule
          )
      },
      {
        path: 'edit-course/:id',
        loadChildren: () =>
          import('./components/studio/editcourse/editcourse.module').then(
            (m) => m.EditcourseModule
          )
      },

      {
        path: 'edit-video/:id',
        loadChildren: () =>
          import('./components/studio/editvideo/editvideo.module').then(
            (m) => m.EditvideoModule
          )
      },
      {
        path: 'tiers',
        loadChildren: () =>
          import('./components/studio/tiers/tiers.module').then(
            (m) => m.TiersModule
          )
      },
      {
        path: 'edit',
        loadChildren: () =>
          import('./components/studio/edit/edit.module').then(
            (m) => m.EditModule
          )
      },
      {
        path: 'edit-tiers',
        loadChildren: () =>
          import('./components/studio/tiersedit/tiersedit.module').then(
            (m) => m.TierseditModule
          )
      }
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' } // Handling unknown routes
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
