import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

export const enum EEvent {
    UPDATE_NAV_HIDDEN = 'UPDATE_NAV_HIDDEN',
    LATEST_URL = 'LATEST_URL'
}

export interface IEvent {
    action: EEvent;
    payload: any;
}

@Injectable({
    providedIn: 'root',
})
export class EventService {
    private subject = new Subject<IEvent>();

    constructor() { }

    push(data: IEvent) {
        this.subject.next(data);
    }

    receive() {
        return this.subject;
    }
}
