<div class="skeleton flex flex-column">
    <div class="skeleton-video" style="height: 172px;">
        <span class="skeleton-box"></span>
    </div>
    <div class="flex flex-column">
        <div class="mt-1" style="width: 180px; height: 18px;">
            <span class="skeleton-box"></span>
        </div>
        <div class="mt-1" style="width: 120px; height: 18px;">
            <span class="skeleton-box"></span>
        </div>
        <div class="mt-1" style="width: 180px; height: 18px;">
            <span class="skeleton-box"></span>
        </div>
    </div>
</div>

