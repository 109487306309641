import { Component, Input, ViewContainerRef, Injector, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { AuthService } from 'src/app/services/auth.service';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';


@Component({
  selector: 'app-all-videos',
  templateUrl: './all-videos.component.html',
  styleUrls: ['./all-videos.component.css', './all-videos.component.scss'],
})
export class AllVideosComponent {
  @Input() fromSearchPage: boolean = false;
  @Input() all: any[] = [];  // Receives all videos from HomeComponent
  @Input() trackById!: (index: number, item: any) => any;
  @Input() page:boolean;
  @Input() studio:boolean = false;
  @Output() loginEvent = new EventEmitter();

  constructor(private router: Router, private authService: AuthService,
    private injectors: Injector,
    private _router: Router
  ) {

  }

 

  onSelect(event: Event, data: any) {
    var a = data.course;
    if(!this.studio){
        const target = event.target as HTMLElement;
        if (target.classList.contains('creator__status')) {
          const user = data.name.replace(/\s+/g, '').toLowerCase();
          this.onSelectpro(user)
        } else {
          // var a = data.course;
          this.router.navigate(['/video', a]);
        }
      }else{
        if(data.videoType === 'course' || data.videoType === 'playlist'){
       
          this.router.navigate(['/studio/edit-course', a]);
        }else{
      
          this.router.navigate(['/studio/edit-video', a]);
      }
    }
  }

  onSelectpro(user) {
    if (this.authService.loggedIn()) {
      this.router.navigate([`p/${user}`]);
    } else {
      this.login();
    }
  }

  login() {
    this.router.navigate(["login"])
  }

  createInjectors(overlayRefs: OverlayRef): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(OverlayRef, overlayRefs);
    return new PortalInjector(this.injectors, injectorTokens);
  }


}
