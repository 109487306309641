// ui.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UiService {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  // Public observable that components can subscribe to
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor() {}

  showLoading() {
    this.loadingSubject.next(true);
  }

  hideLoading() {
    this.loadingSubject.next(false);
  }
}
