import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GoogleAuthService {
  private clientId = "248455518991-ku97743r0ta436qfnb6a4ohp4mhnv3vq.apps.googleusercontent.com"; // Replace with your Google Client ID

  constructor() {}

  // Load Google SDK dynamically
  loadGoogleSDK(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (typeof window !== "undefined" && window.google && window.google.accounts) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error("Google SDK failed to load"));
      document.head.appendChild(script);
    });
  }

  // Initialize Google Sign-In with callback
  initializeGoogleSignIn(callback: (response: any) => void): void {
    if (typeof window !== "undefined" && window.google && window.google.accounts) {
      window.google.accounts.id.initialize({
        client_id: this.clientId,
        callback: callback,
      });

      // Render button only on the client side
      window.google.accounts.id.renderButton(
        document.getElementById("google-signin-btn") as HTMLElement,
        {  logo_alignment: 'center', theme: "outline", size: "large", height:"50"}
      );
    } else {
      console.error("Google API is not available. Make sure SDK is loaded.");
    }
  }
}

