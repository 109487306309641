import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
// import { forkJoin } from "rxjs/observable/forkJoin";

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  avgRating: any;

  constructor(
    public dialogRef: MatDialogRef<StatsComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.avgRating = this.data.avgRating;
  }

  ngOnInit() {
    // var id = {
    //   page: 1
    //
    // }
    // this.authService.Stats(id).subscribe((data) =>{
    //
    //   this.avgRating = data.docs[0].pop
    //   console.log(data.docs[0] + "d")
    //
    //
    // })
    // this.dialogRef.close();
  }
}
