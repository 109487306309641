import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { v4 as uuid } from 'uuid';
import { ToastService } from 'src/app/services/toast.service';
import { ValidateService } from 'src/app/services/validate.service';
import { GoogleAuthService } from "../../services/google-auth.service";
import { FlashMessageService } from '../../services/flashmessages.service';
import { Location } from '@angular/common'; // Import Location
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  password: string = ""
  email: string = ""
  showpassword: boolean = false;

  isForgotPassword: boolean = false;
  currentRoute: string = '';
  previousRoute: string = '';
  navigationLocation: string = '';

  constructor(
    private validateService: ValidateService,
    private authService: AuthService,
    private toastService: ToastService,
    private _router: Router,
    private googleAuthService: GoogleAuthService,
    private flashMessage: FlashMessageService,
    private location: Location,
    private route: ActivatedRoute
   
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.navigationLocation = params['type'];
    });

    this.currentRoute = this._router.url;
    
    this.googleAuthService.loadGoogleSDK()
      .then(() => {
        this.googleAuthService.initializeGoogleSignIn(this.handleCredentialResponse.bind(this));
      })
      .catch(error => {
        console.error("Google SDK load error:", error);
    });

   

  }

  handleCredentialResponse(response: any): void {
    if (response && response.credential) {
      fetch("/api2/googleRegister", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          credential: response.credential
        })
      })
      .then(response => {
        if (!response.ok) {
          this.flashMessage.display('Failed to register with Google', 'danger', 2000);
        }
        return response.json();
      })
      .then(data => {
        if (data.message === 'Registration successful') {
          // Log the user information (for example, from the response)
          console.log("User information:", data.userInfo);  // Assuming the backend sends user data

         this.onLoginGoogleSubmit(data.userInfo)
  
          // Navigate to the register-success page on success
          // this.router.navigate(['/register-success']);
        } else {
          console.error("Unexpected response:", data);
        }
      })
      .catch(error => {
        this.flashMessage.display(error, 'danger', 2000);
      });
    } else {
      this.flashMessage.display('Invalid response or missing credential', 'danger', 2000);

    }
  }

  onLoginGoogleSubmit(user) {
    const userInfo = {
      email: user.email,
      password: user.sub
    };

    this.authService.authenticateUser(userInfo).subscribe((data) => {
      if (data.success) {
        // this.changeDetectorRef.markForCheck();
        this.authService.storeUserData(data.token, data.user);
        // this._loginService.gotoLatestURL().then(res => {

        this.checkRoute()
        
          // this.location.back();
          this.flashMessage.display('You are now logged in', 'success', 2000);
        // })
      } else {
        // this.changeDetectorRef.markForCheck();
        this.flashMessage.display(data.msg, 'danger', 2000);
      }
    });
  }

  toggleShowPassword() {
    this.showpassword = !this.showpassword
  }

  

  login() {
    if (!this.validateService.validateEmail(this.email)) {
      return;
    }

    if (this.isForgotPassword) {
      const user = {
        id: uuid(),
        email: this.email
      };

      this.authService.resetPassword(user).subscribe((data) => {
        if (data.success) {

          this.authService.updateUsertok(user).subscribe((data) => {
            this._router.navigate(["/"]) // Uses the browser's history API to go back one step
            // this._loginService.gotoLatestURL().then(res => {
              this.flashMessage.display('password email sent', 'success', 2000);
            // })
          });
        } else {
          this.flashMessage.display('error reseting passord', 'danger', 2000);
        }
      });

    } else {

      if (this.checkValidForm()) {
        return;
      }

      const user = {
        email: this.email,
        password: this.password
      };

      this.authService.authenticateUser(user).subscribe((data) => {
        if (data.success) {
          this.authService.storeUserData(data.token, data.user);

          this.checkRoute()
          
        
          // this.location.back();
          // this._loginService.gotoLatestURL().then(res => {
            this.flashMessage.display('You are now logged in', 'success', 2000);
          // })
        } else {

          this.flashMessage.display(data.msg, 'danger', 2000);
          // this.toastService.showToast(data.msg, 'error');
        }
      });
    }

  }

 checkRoute(): void {
    if (this.navigationLocation === 'register') {
      // If the previous route was register or login, go to home
     
        window.history.go(-2); // Go back two steps
     
    } else {
      // Otherwise, go back to the last page
      this.location.back();
    }
  }

  isRegisterPage(): boolean {
    // Adjust this condition based on how you check the current route
    return this.location.path() === '/register';
  }

  checkValidForm() {
    if (!this.isForgotPassword) {
      return this.email.trim().length <= 0 || this.password.trim().length <= 0
    } else {
      return this.email.trim().length <= 0
    }
  }

  register() {
    // this._router.navigate(["register"])
    this._router.navigate(["register"], { queryParams: { type: "login" } });
  }

  forgotPassword() {
    this.isForgotPassword = !this.isForgotPassword;
    this.email = ""
  }
}
