<section #videoList>
 
  <div *ngIf="all" class="container">
    <app-top-creator [popularInstructors]="popularInstructors"></app-top-creator>
    <div class="filter__type__zone">
      <div class="filter__type__content">
        <button (click)="setType('all')" class="button-filter-type" [ngClass]="{active: currentFilterType == 'all'}">
          All videos
        </button>
        <button (click)="setType('design')" class="button-filter-type" [ngClass]="{active: currentFilterType == 'design'}">
         Design
        </button>
        <button (click)="setType('crime')" class="button-filter-type"
          [ngClass]="{active: currentFilterType == 'crime'}">
          True crime
        </button>
        <button (click)="setType('music')" class="button-filter-type" [ngClass]="{active: currentFilterType == 'music'}">
          Music
        </button>
      </div>
      <div class="d-flex align-items-center btnContainer">
        <div class="vertical-line"></div>
        <div class="filter-button" (click)="filterDialog()">
          <span>Filters</span>
          <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/filter.svg">
        </div>
        <div class="filter" *ngIf="openFilter">
          <ng-container *ngTemplateOutlet="filter"></ng-container>
        </div>
      </div>
    </div>
    <app-infinity-scroll [elementScroll]="videoList" [templateLoading]="loading" [isDone]="isFetchDone"
      [isUnsubscribe]="isFetchDone">
     

        <app-all-videos class="grid mb-3" [all]="all" [trackById]="trackById" [page]="true"></app-all-videos>

        
      <!-- </div> -->
    </app-infinity-scroll>
  </div>

  <ng-template #loading>
    <div *ngIf="!(mobile | async)!.matches" class="grid mb-3">
      <app-video-skeleton *ngFor="let e of [].constructor(5)"></app-video-skeleton>
    </div>

    <div *ngIf="(mobile | async)!.matches" class="grid mb-3">
      <app-video-skeleton *ngFor="let e of [].constructor(2)"></app-video-skeleton>
    </div>
  </ng-template>

</section>

<ng-template #filter>
  <div class="filter__container">
   
    <div class="flexnew">
    <h2>Rating</h2>
    <button *ngIf="clearFilter" (click)="clearFilters()" class="clear-filters">clear filters</button>
    </div>
    <div *ngFor="let rating of ratingOptions" class="flexy">
      <div class="form-check-inline">
        <label class="form-check-label Pointcursor">
          <input
            type="radio"
            (click)="onSelectReview(rating.value)"
            class="form-check-input Pointcursor"
            name="review"
          />
        </label>
      </div>
      <ul class="rating" [attr.data-rating]="rating.value">
        <li *ngFor="let item of [].constructor(5); let i = index" 
            class="rating__item" [ngClass]="{'filled': i < getFilledStars(rating.value)}"></li>
      </ul>
      <span class="pl-2">{{ rating.value }} & up</span>
    </div>
    <hr />
    <h2>Duration</h2>
    <div *ngFor="let length of lengthOptions" class="flexy">
      <div class="form-check-inline">
        <label class="form-check-label Pointcursor">
          <input
            (change)="onSelectLength($event, length)"
            type="checkbox"
            name="check"
          />
        </label>
      </div>
      <span class="pl-2">
        {{ length.gte }} 
        <ng-container *ngIf="length.lte !== null">- {{ length.lte }}</ng-container> 
        Hours
        <ng-container *ngIf="length.lte === null">+</ng-container>
      </span>
    </div> 
    
  </div>
</ng-template>