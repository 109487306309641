// loading-bar.component.ts
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UiService } from '../services/ui.service';

@Component({
  selector: 'app-loadingbar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingbarComponent implements OnInit, OnDestroy {
  isLoading = false;
  progress = 0;
  private subscription: Subscription;
  private interval: any;

  constructor(
    private uiService: UiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscription = this.uiService.loading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
      this.changeDetectorRef.markForCheck();
      if (isLoading) {
        this.startLoading();
      } else {
        this.completeLoading();
      }
    });
  }

  startLoading() {
    this.progress = 85;
    this.changeDetectorRef.markForCheck();
    this.interval = setInterval(() => {
      // Increment the progress and ensure it's not more than 95%
      this.progress = Math.min(this.progress + 5, 95);
      this.changeDetectorRef.markForCheck();
    }, 2000); // Adjust interval for speed
  }

  completeLoading() {
    clearInterval(this.interval);
    // Quickly complete the loading bar
    this.progress = 100;
    this.changeDetectorRef.markForCheck();
    setTimeout(() => {
      this.isLoading = false;
      this.progress = 0;
      this.changeDetectorRef.markForCheck();
    }, 300); // Delay to show complete bar before hiding
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}