import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UiService } from '../../services/ui.service';
import { filter} from 'rxjs/operators';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainComponent {
  isHide: boolean;
  isLoading = false;
  title = 'vlogmo';
  constructor(
    private router: Router,
    private uiService: UiService
  ) {

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
    
      .subscribe((event: NavigationEnd) => {
        if (this.router.url != '/') {
          this.isHide = true
        }else{
         this.isHide = false
        }
      });
    
  }

  ngOnInit() {
    this.router.events.subscribe(() => {
      if (event instanceof NavigationStart) {
        this.uiService.showLoading();
      } else if (event instanceof NavigationEnd) {
        this.uiService.hideLoading();
      }
    });
  }

}