import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfinityScrollComponent } from '../components/infinity-scroll/infinity-scroll.component'; // Import the component

@NgModule({
  declarations: [InfinityScrollComponent],  // Declare the component here
  imports: [CommonModule],  // Import necessary modules
  exports: [InfinityScrollComponent]  // Export it to make it available in other modules
})
export class sharedInfinityModule { }
