<div [class.active-overlay]="showoverlay" class="overlay">
  <div class="overlay-content">
    <form (submit)="Search()">
      <span class="closebtn" (click)="toggleShowOverlay()" title="Close Overlay"><i
          class="material-icons">arrow_back</i></span>
      <input type="text" placeholder="Search.." [(ngModel)]="search" (ngModelChange)="fetchData()" name="search"
        autocomplete="off" />
      <button type="submit"><i class="material-icons">search</i></button>
      <ng-container *ngIf="search && dataAlgolia.length > 0">
        <ng-template *ngTemplateOutlet="searchResult"></ng-template>
      </ng-container>
    </form>
  </div>
</div>
<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #drawer class="sidenav" [fixedInViewport]="true"
    [attr.role]="(isHandset | async).matches ? 'dialog' : 'navigation'" mode="over" opened="false">
    <div class="sidebar open">
      <div class="header__button__menu" (click)="drawer.toggle()">
        <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/menu.svg">
      </div>
      <div class="bottom-sidebar">
        <ul class="sidebar-list">
          <li class="sidebar-list-item active">
            <a [routerLink]="['/']" (click)="drawer.toggle()" class="sidebar-link pointcursor">
              <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/home-active.svg">
              <div class="hidden-sidebar">Home</div>
            </a>
          </li>
          <li class="sidebar-list-item">
            <a (click)="onSelectNew('messages'); drawer.toggle()" class="sidebar-link pointcursor">
              <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/message.svg">
              <div class="hidden-sidebar message">Messages</div>
              <span *ngIf="Usermessage === 'true'" class="icon__badge"></span>
            </a>
          </li>
          <li class="sidebar-list-item">
            <a (click)="onSelectNew('subscribed'); drawer.toggle()" class="sidebar-link pointcursor">
              <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/memberships.svg">
              <div class="hidden-sidebar">Memberships</div>
            </a>
          </li>
          <li class="sidebar-list-item">
            <a (click)="onSelectNew('purchased'); drawer.toggle()" class="sidebar-link pointcursor">
              <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/purchase.svg">
              <div class="hidden-sidebar">Purchased</div>
            </a>
          </li>
          <li class="sidebar-list-item">
            <a (click)="Creators(); drawer.toggle()" class="sidebar-link pointcursor">
              <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/creator.svg">
              <div class="hidden-sidebar">Creators</div>
            </a>
          </li>
          <li class="sidebar-list-item">
            <a (click)="onUpload(); drawer.toggle()" class="sidebar-link pointcursor">
              <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/studio.svg">
              <div class="hidden-sidebar">Studio</div>
            </a>
          </li>
          <li class="sidebar-list-item">
            <a [routerLink]="['/help']" (click)="drawer.toggle()" class="sidebar-link">
              <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/support.svg">
              <div class="hidden-sidebar">Support</div>
            </a>
          </li>
          <li *ngIf="authService.loggedIn()" class="sidebar-list-item">
            <a (click)="onLogoutClick(); drawer.toggle()" class="sidebar-link">
              <i class="ri-shut-down-line logoutIcon"></i>
              <div class="hidden-sidebar">Log out</div>
            </a>
          </li>
        </ul>
      </div>
      <div class="hidden-sidebar pads your-channel">
        Recommended Creators
      </div>
      <div class="rcm-channel">
        <div class="middle-sidebar">
          <ul class="sidebar-list">
            <li *ngFor="let popularInstructors of popularInstructors" class="sidebar-list-item">
              <a style="padding: 16px 8px !important;"
                (click)="onSelectpro(popularInstructors.User.searchPageName); drawer.toggle()"
                class="sidebar-link pointcursor">
                <mat-card style="display: none"></mat-card>
                <img class="header6Option__iconno" mat-card-avatar [src]="popularInstructors.User.profile"
                  alt="User Avatar" />
                <div class="hidden-sidebar">
                  {{ popularInstructors.User.name }}
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- <div [class.myOverlay]="showoverlay" class="overlay">
      <div class="overlay-content">
        <form (submit)="Search()">
          <span class="closebtn" (click)="toggleShowOverlay()" title="Close Overlay"><i
              class="material-icons">arrow_back</i></span>
          <input type="text" placeholder="Search.." [(ngModel)]="search" name="search" />
          <button type="submit"><i class="material-icons">search</i></button>
        </form>
      </div>
    </div> -->
    <img *ngIf="!hide" [routerLink]="['/']" class="header__logo__tablet pointer"
      src="https://s3bucket190.s3.us-east-1.amazonaws.com/download__4___2_-removebg-preview%2B(1).png" />

    <div class="flexcontent">
      <div *ngIf="!(tablet | async)!.matches && !hide" class="sidebar" [class.open]="showside">
        <div class="header__button_menu" (click)="action(drawer)">
          <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/menu.svg">
        </div>
        <div [routerLink]="['/']" class="sidebar__header pointcursor" *ngIf="showside">
          <img class="header__logo pointer"
            src="https://s3bucket190.s3.us-east-1.amazonaws.com/download__4___2_-removebg-preview%2B(1).png" />
          <span class="header__Vlogmo">Vlogmo</span>
        </div>
        <div class="tab" *ngIf="!showside">
          <mat-card style="display: none"></mat-card>
          <img mat-card-avatar [src]="profile" onerror="this.src='assets/img/profile.png';" />
        </div>
        <div class="bottom-sidebar">
          <ul class="sidebar-list">
            <li class="sidebar-list-item active">
              <a [routerLink]="['/']" class="sidebar-link pointcursor">
                <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/home-active.svg">
                <div class="hidden-sidebar">Home</div>
              </a>
            </li>
            <li class="sidebar-list-item">
              <a (click)="onSelectNew('messages')" class="sidebar-link pointcursor">
                <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/message.svg">
                <div class="hidden-sidebar message">Messages</div>
                <span *ngIf="Usermessage === 'true'" class="icon__badge"></span>
              </a>
            </li>
            <li class="sidebar-list-item">
              <a (click)="onSelectNew('subscribed')" class="sidebar-link pointcursor">
                <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/memberships.svg">
                <div class="hidden-sidebar">Memberships</div>
              </a>
            </li>
            <li class="sidebar-list-item">
              <a (click)="onSelectNew('purchased')" class="sidebar-link pointcursor">
                <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/purchase.svg">
                <div class="hidden-sidebar">Purchased</div>
              </a>
            </li>
            <li class="sidebar-list-item">
              <a (click)="Creators()" class="sidebar-link pointcursor">
                <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/creator.svg">
                <div class="hidden-sidebar">Creators</div>
              </a>
            </li>
            <li class="sidebar-list-item">
              <a (click)="onUpload()" class="sidebar-link pointcursor">
                <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/studio.svg">
                <div class="hidden-sidebar">Studio</div>
              </a>
            </li>
            <li class="sidebar-list-item">
              <a [routerLink]="['/help']" class="sidebar-link">
                <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/support.svg">
                <div class="hidden-sidebar">Support</div>
              </a>
            </li>
            <li *ngIf="authService.loggedIn()" class="sidebar-list-item">
              <a (click)="onLogoutClick()" class="sidebar-link">
                <i class="ri-shut-down-line logoutIcon"></i>
                <div class="hidden-sidebar">Log out</div>
              </a>
            </li>
          </ul>
        </div>
        <div class="hidden-sidebar pads your-channel">
          Recommended Creators
        </div>
        <div class="rcm-channel">
          <div class="middle-sidebar">
            <ul class="sidebar-list">
              <li *ngFor="let popularInstructors of popularInstructors" class="sidebar-list-item">
                <a style="padding: 16px 8px !important;" (click)="onSelectpro(popularInstructors.User.searchPageName)"
                  class="sidebar-link pointcursor">
                  <mat-card style="display: none"></mat-card>
                  <img class="header6Option__iconno" mat-card-avatar [src]="popularInstructors.User.profile"
                    alt="User Avatar" />
                  <div class="hidden-sidebar">
                    {{ popularInstructors.User.name }}
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content_zone" [class.expand]="showside && !hide" [class.hide]="hide && !checkURL()"
        [class.full]="checkURL()">
        <nav [class.scaleable]="showside && !hide" [class.hide]="hide" *ngIf="!checkURL()">
          <div class="d-flex align-items-center" *ngIf="hide && !(mobile | async).matches">
            <div class="header__right__menu" style="margin-right: 30px;" (click)="drawer.toggle()">
              <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/menu.svg">
            </div>
            <div [routerLink]="['/']" class="sidebar__header pointcursor">
              <img class="header__logo pointer"
                src="https://s3bucket190.s3.us-east-1.amazonaws.com/download__4___2_-removebg-preview%2B(1).png" />
              <span class="header__Vlogmo">Vlogmo</span>
            </div>
          </div>
          <div class="header__search">
            <form (submit)="Search()" class="search-form" [class.left]="!showside" [class.hide]="hide">
              <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/search-input.svg">
              <input placeholder="Search here" [(ngModel)]="search" (ngModelChange)="fetchData()" name="search"
                class="header__searchInput" type="text" autocomplete="off">
              <ng-container *ngIf="search && dataAlgolia.length > 0">
                <ng-template *ngTemplateOutlet="searchResult"></ng-template>
              </ng-container>
            </form>
            <div class="header__filter__type" *ngIf="!hide">
              <button (click)="filterVideos('all')" class="button-filter-type"
                [ngClass]="{active: currentFilterType === 'all'}">
                All videos
              </button>
              <button (click)="filterVideos('singleVideo')" class="button-filter-type"
                [ngClass]="{active: currentFilterType === 'singleVideo'}">
                Videos
              </button>
              <button (click)="filterVideos('playlist')" class="button-filter-type"
                [ngClass]="{active: currentFilterType === 'playlist'}">
                Playlists
              </button>
              <button (click)="filterVideos('course')" class="button-filter-type"
                [ngClass]="{active: currentFilterType === 'course'}">
                Courses
              </button>
              <!-- <button (click)="filterVideos('live')" class="button-filter-type"
                [ngClass]="{active: currentFilterType === 'live'}">
                Live
              </button> -->
            </div>
          </div>

          <div class="header__right__content">
            <div *ngIf="authService.loggedIn()" class="studio pointer" (click)="onUpload()">
              <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/studio-bg-black.svg">
              <span>Studio</span>
            </div>
            <div class="sidebar__header">
              <img [routerLink]="['/']" class="header__logo pointer"
                src="https://s3bucket190.s3.us-east-1.amazonaws.com/download__4___2_-removebg-preview%2B(1).png" />
              <span class="header__Vlogmo">Vlogmo</span>
            </div>
            <div class="d-flex align-items-center">
              <div (click)="toggleShowOverlay()" class="header__right__search mr-2 pointcursor">
                <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/search-bg-black.svg">
              </div>
              <div style="margin-left: 32px;" class="studio" *ngIf="!authService.loggedIn()"
                (click)="showGlobalOverlay()">
                <!-- <img src="../../../assets/img/logo-home/studio-bg-black.svg"> -->
                <span>Login</span>
              </div>
              <div style="margin-left: 32px;" class="studio" *ngIf="!authService.loggedIn() else auth"
                (click)="showGlobalOverlays()">
                <!-- <img src="../../../assets/img/logo-home/studio-bg-black.svg"> -->
                <span>Sign Up</span>
              </div>
              <ng-template #auth>
                <div class="header__right" [ngClass]="{ gap: authService.loggedIn() }">
                  <div class="dropdown search d-inline-block stopevent mr-3">
                    <a (click)="onNotificationCheck()" [class.indicator]="Usernotification === 'true'"
                      class="btn btn-lights btn-icon rounded-circle text-muted indicator-primary icon-size-sm" href="#"
                      role="button" id="dropdownNotificationSecond" data-bs-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <img width="24" height="26" class="pointer" (click)="onNotificationCheck()"
                        src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/notification1.png">
                    </a>
                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-lg"
                      aria-labelledby="dropdownNotificationSecond">
                      <div>
                        <div class="border-bottom px-3 pb-3 d-flex justify-content-between align-items-center">
                          <span class="h5 mb-0">Notifications</span>
                        </div>
                        <div class="slimScrollDiv">
                          <img *ngIf="no" src="https://cdn.iconscout.com/icon/free/png-256/no-notification-458651.png"
                            style="
                              margin-left: 45%;
                              height: 50px;
                              width: 50px;
                              margin-top: 30%;
                            " alt="" class="align-items-center justify-content-center" />
                          <ul class="list-group list-group-flush notification-list-scroll">
                            <li *ngFor="let notifications of notifications"
                              class="list-group-item bg-light pointcursor">
                              <a class="text-body" (click)="HighlightedComment(notifications)">
                                <div class="d-flex text-wrap">
                                  <img [src]="notifications.comments.profile" style="height: 50px; width: 50px"
                                    onerror="this.src='assets/img/profile.png';" class="avatar-md rounded-circle" />
                                  <div class="commentspan">
                                    <h5 class="fw-bold mb-1">
                                      {{ notifications.comments.name }}:
                                    </h5>
                                    <p class="mb-3" style="overflow-wrap: anywhere">
                                      {{ notifications.comments.name }}
                                      <b>{{ notifications.comments.status }}</b> your
                                      comment "{{
                                      notifications.comments.comment.length > 125
                                      ? (notifications.comments.comment
                                      | slice: 0 : 125) + ".."
                                      : notifications.comments.comment
                                      }}"
                                    </p>
                                    <span class="font-size-xs text-muted">
                                      <span>
                                        <span class="fe text-success mr-1"
                                          [ngClass]="notifications.comments.status === 'liked' ? 'fe-thumbs-up' : 'fa-reply'">
                                        </span>
                                        {{ notifications.comments.time | timeago }}</span>
                                    </span>
                                  </div>
                                  <div class="ml-2 mr-2">
                                    <img [src]="notifications.comments.cover" style="height: 75px; width: 115px" alt=""
                                      class="avatar-md" />
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="border-top px-3 pt-3 pb-0"></div>
                      </div>
                    </div>
                  </div>

                  <div class="dropdown pp pointerCursor"
                    *ngIf="!(mobile | async).matches && ((hide && !showside) || (!hide && showside))">
                    <div class="tab">
                      <mat-card style="display: none"></mat-card>
                      <img mat-card-avatar [src]="profile" onerror="this.src='assets/img/profile.png';" />
                      <!-- <a class="btn-sm rounded-circle icon-size-sm">
                        <i class="far fa-user" style="font-size: 13px !important;"></i>
                      </a> -->
                    </div>
                    <div class="dropdown-content">
                      <a [routerLink]="['/purchased']">Purchase History</a>
                      <a [routerLink]="['/subscribed']">Memberships</a>
                      <a [routerLink]="['/edit']">Edit Profile</a>
                      <a [routerLink]="['/creators']">Find Creators</a>
                      <!-- <a [routerLink]="['/studio/user']">Upload</a> -->
                      <a [routerLink]="['/help']">Help</a>
                      <a (click)="onLogoutClick()">Logout</a>
                    </div>
                  </div>
                </div>
              </ng-template>
              <div class="header__right__menu" (click)="drawer.toggle()">
                <img src="https://s3bucket190.s3.us-east-1.amazonaws.com/vlogmoimages/logo-home/menu.svg">
              </div>
            </div>
          </div>
        </nav>
        <div class="main__content" [class.full]="checkURL()">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #searchResult>
  <div class="search__result">
    <div class="item" *ngFor="let result of dataAlgolia" (click)="gotoSearch(result.title)">
      <div [innerHTML]="result.title"></div>
      <img *ngIf="(mobile | async).matches" [src]="result.pic">
    </div>
  </div>
</ng-template>