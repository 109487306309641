import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LoadingbarComponent } from './loading-bar/loading-bar.component'; // Update the path
import { FlashmessageComponent } from './flashmessage/flashmessage.component'; // Update the path
import { UiService } from './services/ui.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { DialogCommunicationService } from './services/dialog-communication.service'; // Import the service
import { NgxPaginationModule } from 'ngx-pagination';
// import { RegisterComponent } from './registerold/register.component';

// import { RegisterSuccessComponent } from './components/register-success/register-success.component';
import { ToastComponent } from './components/toast/toast.component';

// import { LoginComponent } from './login/login.component';

import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatDialogModule } from '@angular/material/dialog';
import { ValidateService } from './services/validate.service';
import { MatSpinner } from '@angular/material/progress-spinner';
import { LayoutModule } from '@angular/cdk/layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { ScrollingModule } from '@angular/cdk/scrolling';
// import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk/scrolling';
import { MatBadgeModule } from '@angular/material/badge';
import { FollowersComponent } from './components/navbar/followers/followers.component';
import { StatsComponent } from './components/navbar/stats/stats.component';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MainComponent } from './routernav/main/main.component';
import { TimeagoModule } from 'ngx-timeago';
import { NavBottomComponent } from './components/nav-bottom/nav-bottom.component';
import { HomeComponent } from './components/home/home.component';
// import { InfinityScrollComponent } from './components/infinity-scroll/infinity-scroll.component';
// import { VideoSkeletonComponent } from './components/video-skeleton/video-skeleton.component';

import { TopCreatorComponent } from './components/home/top-creator/top-creator.component';
import { sharedAllVideosModule } from './shared/all-videos.module';
import { sharedInfinityModule } from './shared/infinityScroll.module';
import { videoSkeletonModule } from './shared/video-skeleton.module';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';

@NgModule({
  declarations: [
    HomeComponent,
    AppComponent,
    RegisterComponent,
    LoginComponent,
    FollowersComponent,
    StatsComponent,
    NavbarComponent,
    MainComponent,
    LoadingbarComponent,
    FlashmessageComponent,
    NavBottomComponent,
    TopCreatorComponent,
    ToastComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    MatCardModule,
    MatSidenavModule,
    MatButtonModule,
    OverlayModule,
    MatIconModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    LayoutModule,
    MatToolbarModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatBadgeModule,
    sharedInfinityModule,
    sharedAllVideosModule,
    videoSkeletonModule,
    TimeagoModule.forRoot(),
    MatDialogModule
  ],
  providers: [
    ValidateService,
    UiService,
    DialogCommunicationService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FollowersComponent,
    StatsComponent,
  ]
})
export class AppModule {}
