import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import {
  FlashMessageService,
  FlashMessage
} from '../../app/services/flashmessages.service';

@Component({
  selector: 'app-flashmessage',
  templateUrl: './flashmessage.component.html',
  styleUrls: ['./flashmessage.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlashmessageComponent {
  flashMessage: FlashMessage | null = null;

  constructor(
    private flashMessageService: FlashMessageService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.flashMessageService.message$.subscribe((msg) => {
      this.flashMessage = msg;
      this.changeDetectorRef.markForCheck();
    });
  }
}